@import url('https://fonts.googleapis.com/css?family=Roboto');
body{
  font-family: "Roboto"
}
.navbar{
  padding: 0;
}
.border-translate{
  border-right: 1px solid #DFDFDF;
  border-left: 1px solid #DFDFDF;
}

.border-col{
  border: 1px solid rgba(255,255,255,0.5);
}
.border-col-4{
  border:1px solid #DFDFDF;
}

.content-add-image{
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-add-image .btn{
  margin: 0;
  border-radius: 50px;
}
.content-table .modal-dialog .modal-body .btn{
  border: 1px solid #1C1C1C;
  border-radius: 50px;
  padding-left: .5rem;
  padding-right: .5rem;
}
@media only screen and (min-width: 320px) {
  .historyContent h4{
    color: #1C1C1C;	
    font-size: 14px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }

  .infoHistory{
    position: absolute;
    top: 0;
    padding: 17px;
    left: 0;
    z-index: 2;
  }

  .widthUniservice{
    width: 152px;
    height: 29px;
  }
  .widthUniLatina{
    width: 136px;
    height: 32px;
  }
  .widthMarin{
    width: 70px;
    height: 73px;
  }
  .widthTeco{
    width: 104px;
    height: 61px;
  }
  .widthBlutec{
    width: 160px;
    height: 60px;
  }
  .widthChemo{
    width: 136px;
    height: 32px;
  }
  .widthNavadan{
    width: 152px;
    height: 29px;
  }
  .widthMarichem{
    width: 198px;
    height: 35px;
  }
  .widthWorld{
    width: 152px;
    height: 55px;
  }
  .marca{
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    align-self: center;
  }
  .historyContent{
    width: 100%;
    background-color: #F5F5F5;
    position: relative;
  }
  .imgHistory{
    height: 1100px;
    background-image: url("../assets/newAssets/Amazonas.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    filter: opacity(0.2) grayscale(100%);
  }

  .titleMain{
    color: #1C1C1C;	
    font-size: 18px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center
  }
  .sign{
    background: #F5F5F5;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 10px 15px;
  }
  .sign h5{
    color: #1C1C1C;	
    margin-top: 22px;
    font-weight: 400;	
    text-align: center;
    font-size: 16px;	
    line-height: 19px;
  }
  .history p{
    color: #1C1C1C;	
    font-size: 14px;	
    line-height: 17px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .linkDown{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 5px
  }
  .linkDown a{
    display: flex;
    color: #1C1C1C
  }
  .linkDown a span{
    margin-right: 5px;
    align-self: center;
    line-height: 15px;
  }
  .worldIcon{
    width: 25px
  }

  .congrat h3{
    color: #1C1C1C;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .congrat p,.congrat p a{
    color: #1C1C1C;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .congrat p a{
    text-decoration: underline
  }
  .infoHistory hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0;
    border-top: 2px solid #bbb;
  }
}
@media only screen and (min-width: 576px) {
  .imgHistory{
    height: 950px;
  }
  .infoHistory{
    padding: 40px 75px;
  }
}

@media only screen and (min-width: 992px) {
  .imgHistory{
    height: 870px;
  }

  .linkDown{
    padding: 0
  }
  .worldIcon{
    width: 50px
  }
  .infoHistory{
    position: absolute;
    top: 0;
    width: 100%;
    padding: 65px 100px;
    left: 0;
    z-index: 2;
  }
  .sign{
    padding: 40px 50px;
  }
  .history h4{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .congrat h3{
    font-size: 16px;
  }
  .congrat p,.congrat p a{
    font-size: 16px;
  }
  .history p{
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .titleMain{
    color: #1C1C1C;	
    font-size: 24px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 28px;
    margin-bottom: 20px;
  }
  .sign h5{
    text-align: left;
    color: #1C1C1C;	
    margin-top: 18px;
    font-weight: 400;	
    margin-bottom: 20px;
    font-size: 16px;	line-height: 19px;
  }
  .sign h4{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 600;
  }

  /* .widthTeco{
    width: 127px;
    height: 73px;
  }
  .widthBlutec{
    width: 174px;
    height: 66px;
  }
  .widthChemo{
    width: 174px;
    height: 40px;
  }
  .widthNavadan{
    width: 186px;
    height: 37px;
  }
  .widthMarichem{
    width: 265px;
    height: 47px;
  }
  .widthWorld{
    width: 172px;
    height: 61px;
  } */
  .marca{
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 400
  }
  .marca div{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;    
  }

  .marca .lat-left{
    justify-content: left;
  }

  .marca .norm{
    justify-content: center;
  }
  .marca .lat-lg-left{
    justify-content: left
  }
  .marca .lat-lg-center{
    justify-content: center
  }

  .content-img-marca{
    text-align: left;
  }

  .historyContent{
    border-radius: 20px;
  }

  .linkDown{
    margin-top: 50px;
  }
  .linkDown a{
    align-content: center;
  }
  .linkDown a span{
    margin-right: 5px;
  }
}

@media only screen and (min-width: 1024px) {

  .widthUniservice{
    width: 225px;
    height: 50px;
  }
  .widthUniLatina{
    width: 244px;
    height: 40px;
  }
  .widthMarin{
    width: 70px;
    height: 73px;
  }
  .widthTeco{
    width: 127px;
    height: 73px;
  }

  .widthBlutec{
    width: 174px;
    height: 66px;
  }
  .widthChemo{
    width: 174px;
    height: 40px;
  }
  .widthNavadan{
    width: 186px;
    height: 37px;
  }
  .widthMarichem{
    width: 265px;
    height: 47px;
  }
  .widthWorld{
    width: 172px;
    height: 61px;
  }


}
@media only screen and (min-width: 1200px) {
  .marca .lat-xl-left{
    justify-content: left
  }
  .marca .lat-xl-center{
    justify-content: center
  }
  .infoHistory{

    padding: 65px 200px;

  }
}