@media only screen and (min-width: 320px) {
    .showList {
        display: none;
    }
    footer.page-footer {
        background-color: #EDEDED;
        color: #1C1C1C;
        padding: 45px 0 0;
    }
    .logo-2 {
        width: 231px;
        margin-top: 24px
    }
    .logo-1 {
        margin-top: 15px;
        width: 270px;
    }
    footer.page-footer .option-contact {
        margin-bottom: 15px;
        text-align: center
    }
    footer.page-footer h5{
        text-align: center;
    }
    .popover_parent .drop,
    footer.page-footer .option a,
    footer.page-footer h5,
    .contact h4{
        font-weight: bold;
    }
    footer.page-footer .contact p
     {
        font-weight: 400;
        display: flex;
        flex-direction: column
    }
    .popover_parent .drop,
    footer.page-footer .option a,
    footer.page-footer .contact a,
    footer.page-footer .contact p,
    footer.page-footer h4{
        color: #1C1C1C;
        font-size: 14px;
        line-height: 16px;
    }

    .popover_parent {
        display: none;
    }
    .page-footer .row {
        flex-wrap: wrap-reverse;
    }
    .content-logo {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        padding-bottom: 66px;
    }

    footer.page-footer .footer-copyright{
        overflow: hidden;
        background-color: unset;
        color: #1C1C1C;
        font-family: "Roboto";
        font-size: 10px;
        line-height: 11px;
        text-align: center;
        width: 220px;
        margin: 0 auto;
        font-weight: 500;
        padding-bottom: 18px;
    }
    footer.page-footer .link-footer{
        overflow: hidden;
        background-color: unset;
        color: #1C1C1C;
        font-family: "Roboto";
        line-height: 11px;
        font-size: 10px;
        text-align: center;
        width: 220px;
        margin: 0 auto;
        padding-bottom: 18px;
    }
    footer hr {
        box-sizing: border-box;
        height: 1px;
        width: 89px;
        border: 1px solid #979797;
        margin-top: 0;
    }
}
@media only screen and (min-width: 768px) {
    }
@media only screen and (min-width: 992px) {
    .logo-2 {
      width: 272px
    }
    .logo-1 {
      margin-top: 15px;
      width: 320px
    }
    .content-logo {
        align-items: unset;
    }
    .showList {
        display: block;
    }
    footer.page-footer {
        padding-top: 81px;
    }
    footer.page-footer a {
        color: unset;
    }
    .page-footer .row {
        flex-wrap: wrap;
    }
    .popover_parent {
        position: relative;
        display: inline-block;
    }

    footer.page-footer .option-contact{
        text-align: left;
    }
    footer.page-footer h5{
        text-align: left;
    }
    footer.page-footer h5{
        line-height: 2;
    }
    .popover_parent .fas {
        line-height: 15px;
        margin-left: 8px;
    }

    footer.page-footer .footer-copyright {
        width: unset;
        text-align: right;
        margin-top: 79px;
        padding-right: 38px;
        padding-bottom: 35px;
    }

    .popovers .btn {
        background-color: #F51A0E;
        color: #FFFFFF;
        width: 100%;
        margin: 0;
        box-shadow: unset;
        margin-top: 18px;
    }
    .popovers h3 {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 19px;
    }
    .popovers label {
        color: #666666;
        font-size: 14px;
        line-height: 26px;
    }

    .popover_parent form .form-control {
        height: 48px;
        width: 286px;
        border: 1px solid #AAAAAA;
        border-radius: 0;
    }
    .popover_parent form .form-group {
        margin-bottom: 7px;
    }

    .dropdown-item {
        padding: 0.3rem 0;
        color: #666666;
        font-size: 10px;
        line-height: 26px;
    }

    .logo-2 {
        margin-top: 34px;
    }
    .logo-1,
    .logo-2 {
        margin-left: 100px;
    }

    footer.page-footer .active .popover {
        display: block;
    }

    .divided {
        display: none;
    }

    footer.page-footer .active {
        color: #F51A0E!important;
        display: flex;
        text-transform: uppercase;
        font-weight: 900;
        cursor: pointer;
    }
    footer.page-footer .active hr {
        display: block;
        border-top: 5px solid #F51A0E;
        width: 100%;
        margin: 0;
    }
    footer.page-footer .active + i {
        color: #F51A0E!important;
        font-size: 15px;
    }

    .popovers a {
        font-family: "Roboto";
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }

    .popover-content {
        background: #fff;
        padding: 39px 33px 21px;
        border-radius: 0;
        position: absolute;
        top: -23.5em;
        left: -250px;
        z-index: 4;
        max-width: 352px;
        color: #333;
        box-shadow: 0 0 10px 4px rgba(190,190,190,0.5);
    }

    .popover-content.-arrow {
        margin-top: 10px;
    }

    .popover-content.-arrow::before,
    .popover-content::after {
        content: "";
        position: absolute;
        left: 270px;
        top: 330px;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: 20px solid #fff;
    }

    .context-menu {
        width: 200px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .context-menu-item {
        transition: background-color 0.1s;
    }

    .context-menu-link {
        display: block;
        padding: 3px 7px;
        text-decoration: none;
        color: #333;
    }
    .context-menu-link:visited {
        color: #333;
    }
    .context-menu-link:hover {
        color: #fff;
        background-color: #3367d6;
    }
    footer.page-footer .contact p
    {
       flex-direction: row
   }

}
@media only screen and (min-width: 1200px) {

}
@media only screen and (min-width: 1500px) {

}

.padding-tab {
    padding-left: 178px;
    padding-right: 185px;
}
