@media only screen and (min-width: 320px) {

  .showNavItem{
    display: none
  }

  .main .navbar-brand 
  img{
    width: 146px;
  }

  .main .navbar-brand{
    margin-left: 20px;
  }
  .main .navbar-nav {
    display: flex;
    text-align: center;
  }
  
  /* ------------------------- slide nav ----------------- */
  .main #hambmenu {
    position: relative;
    width: 27px;
    height: 45px;
    margin-right: 5px;
    float: left;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    color: #000

  }
  .main #hambmenu span {
    position: absolute;
    height: 2px;
    vertical-align: middle;
    margin: 10px 5px;
    background: #000;
    border-radius: 8px;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    padding: 0px;
  }
  
  .main .hambclicker {
    content: "";
    height: 48px;
    width: 55px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .main #hambmenu 
  span:nth-child(1) {
    top: 5px;
  }
  
  .main #hambmenu span:nth-child(2), 
  .main #hambmenu span:nth-child(3) {
    top: 10px;
  }
  
  .main #hambmenu span:nth-child(4) {
    top: 15px;
  }
  
  .main #hambmenu.isopen span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
  }
  
  .main #hambmenu.isopen span:nth-child(2) {
    transform: rotate(45deg);
    background-color: #FFFFFF;
    z-index: 10;
  }
  
  .main #hambmenu.isopen span:nth-child(3) {
    transform: rotate(-45deg);
    background-color: #FFFFFF;
    z-index: 10;
  }
  
  .main #hambmenu.isopen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
  }
  
  .main #menu {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100vh;
    width: 100%;
    background-color: rgba(28,28,28,0.85);;
    transform: translateX(-100%);
    transition: transform 300ms;
  }
  .main #menu ul {
    margin-top: 69px;
    padding: 0px;
    list-style-type: none;
    text-align: left;
  }
  .main #menu li {
    padding: 30px 0 20px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }
  .main #menu li:last-child {
    border-bottom: none;
  }
  
  .main #menu.isopen {
    transform: none;
  }
  
  .main .nav-lateral{
    padding-left: 0px;
    list-style: none;
  }
  .main .nav-lateral a{
    text-decoration: none;
    display: block;
    transition: 0.3s;
    color: #FFFFFF;	
    font-size: 20px;	
    font-weight: bold;	
    line-height: 24px;
  }
  .main .content-nav-lateral{
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 576px) {
  .main .navbar-brand{
    margin-left: 75px;
  }
  .main #menu li {
    padding: 30px 0 20px 50px;
  }
}
@media only screen and (min-width: 992px) {
  .main .navbar-brand{
    margin-left: 20px;
  }
  header.main .navbar {
    padding-left: 80px;
    padding-right: 1rem;
  }
  .main .navbar-brand 
  img{
    width: 186px;
    height: 23px;
  }
  .main .navbar-nav .nav-item {
    padding: 27px 23px;
    line-height: 3
  }
  header.main .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    font-size: 15px;
    color: #1C1C1C;
    font-weight: bold;
    position: relative;
    height: 20px;
    line-height: 1.5;
    padding: 0;
    padding-right: .0;
    padding-left: .0;
  }
  .main .nav-item .inactive hr{
    position: absolute;
    margin: 0;
    bottom: -3px;
    width: 100%;
    border: unset
  }
  .main .nav-item .inactive hr:before{
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    bottom: -2px;
    border-top:4px solid rgb(245, 26, 14);
    transition: all 0.4s 0.3s ease-in-out;
  }
  .main .navbar.navbar-dark .navbar-nav .nav-item.active>.nav-link:hover,
  .main .navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover{
    color: #000;
  }
  .main .nav-item .inactive:hover hr:before{
    width: 100%;
  }
  .main .underLine hr{
    margin: 0;
    position: absolute;
    bottom: -5px;
    width: 100%;
    border-top: 4px solid #F51A0E;
  }
  /* ------------------------- slide nav ----------------- */
  .main .menu-nav{
    display: none;
  }
  .main .isopen{
    display: none;
  }
  .showNavItem{
    display: flex;
  }
  .main #hambmenu span,.hambclicker {
    display: none;
  }
}