
@media only screen and (min-width: 320px) {
    .card-header{
        padding: 15px 16px;
    }
    .Acordion .card .card-body,
    .Acordion .card-body{
        padding-left: 55px;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 11px;  
        background-color: #f5f5f5;
    }
    .Acordion .card-body{
        padding-left: 55px;
    }
    .Acordion .card .card-body h3,
    .Acordion .card-body h3{
        color: #1C1C1C;	 
        font-size: 12px;	
        font-weight: bold;	
        line-height: 21px;
    }
    .Acordion .card .card-body p,
    .Acordion .card-body p{
        color: #1C1C1C;
        font-size: 12px;	
        line-height: 19px;
    }
    .Acordion .card-body p{
        font-weight: 400;
    }
    .content-enlace{
        display: flex;
    }
    .content-enlace .enlace{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-self: center;
        margin-right: 21px;
    }
    .enlace span{
        color: #1C1C1C;	
        font-size: 9px;	
        font-weight: bold;	
        line-height: 10px;
    }

    .card-header .btn{
        color: #1C1C1C;	
        font-family: "Roboto";	
        font-size: 12px;	
        font-weight: bold;	
        line-height: inherit;
        padding: 0;
        margin: 0;
        text-align: left;
        display: flex
    }
    .border-collapse{
        border-bottom: 1px solid #DFDFDF;
    }
    .card-header .btn i{
        font-size: 20px;
        margin-right: 16px
    }
    .enlaceLimit{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .enlaceLimit img{
        width: 50px;
        margin-bottom: 10px;
    }
    .enlaceLimit span{
        color: #1C1C1C;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        line-height: 12px;
        margin-bottom: 10px;
    }
    
    .Acordion .card{
        background-color: #f5f5f5;
    }
    .test-kit .card-title{
        font-size: 16px;
        font-weight: 500;
    }
    .test-kit .btn span{
        font-weight: 500;
    }
    
    .btn-link:focus{
        text-decoration: none;
    }
    .btn-link:hover  span{
        text-decoration: none;
    }
    
    .Acordion .content-section .form-inline input::placeholder{
        font-size: 18px;
    }
    .Acordion .content-section .form-inline .form-control{
        padding: 13px 10px;
        font-size: 18px;
        background: url('../assets/lupa.svg') no-repeat right;
        margin: 0;
    }
    .Acordion .content-section .form-inline input[type=text]:focus:not([readonly]){
        border-bottom: 1px solid #1C1C1C
      }
    
      .md-form input[type=text]:focus:not([readonly]){
          box-shadow: none;
      }
      .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link:hover{
          text-decoration: none
      }

}
@media only screen and (min-width: 992px) {
    .Acordion .card .card-body,
    .Acordion .card-body{
        display: flex;
        justify-content: space-between;
        padding-left: 95px;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 11px;  
        background-color: #f5f5f5;
    }
    .Acordion .card-body{
        padding-left: 55px;
    }
    .Acordion .card .card-body h3,
    .Acordion .card-body h3{
        color: #1C1C1C;	 
        font-size: 18px;	
        font-weight: bold;	
        line-height: 21px;
    }
    .Acordion .card .card-body p,
    .Acordion .card-body p{
        color: #1C1C1C;
        font-size: 16px;	
        line-height: 19px;
    }
    .Acordion .card-body p{
        font-weight: 400;
    }
    .content-enlace{
        display: flex;
    }
    .content-enlace .enlace{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-self: center;
        margin-right: 21px;
    }
    .enlace span{
        color: #1C1C1C;	
        font-size: 9px;	
        font-weight: bold;	
        line-height: 10px;
    }

    .card-header{
        padding: 16px 25px;
    }
    .card-header .btn{
        color: #1C1C1C;	
        font-family: "Roboto";	
        font-size: 18px;	
        font-weight: bold;	
        line-height: inherit;
        padding: 0;
        margin: 0;
    }
    .border-collapse{
        border-bottom: 1px solid #DFDFDF;
    }
    .card-header .btn i{
        font-size: 25px;
        margin-right: 45px
    }
    .enlaceLimit{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .enlaceLimit img{
        width: 65px;
        height: 73px;
        margin-bottom: 10px;
    }
    .enlaceLimit span{
        color: #1C1C1C;
        font-size: 14px;
        /* width: 200px; */
        text-align: center;
        font-weight: 500;
        line-height: 14px;
        margin-bottom: 10px;
    }

    .Acordion .card{
        background-color: #f5f5f5;
    }
    .test-kit .card-title{
        font-size: 16px;
        font-weight: 500;
    }
    .test-kit .btn span{
        font-weight: 500;
    }

    .btn-link:focus{
        text-decoration: none;
    }


    .Acordion .content-section .form-inline input::placeholder{
        font-size: 18px;
    }
    .Acordion .content-section .form-inline .form-control{
        padding: 13px 10px;
        font-size: 18px;
        background: url('../assets/lupa.svg') no-repeat right;
        margin: 0;
    }
    .Acordion .content-section .form-inline input[type=text]:focus:not([readonly]){
        border-bottom: 1px solid #1C1C1C
    }

  .md-form input[type=text]:focus:not([readonly]){
      box-shadow: none;
  }
}
