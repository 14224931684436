@media only screen and (min-width: 320px) {


  /* ------------------------ section - certificacion--------------  */
  .img-content{
    width: 300px;
    height: 200px;
    border: 1px solid #e6e6e6;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,.04);
  }
  .img-content img{
    width: 100%
  }
  .pt-25{
    padding-top: 50px;
  }
  .pb-25{
    padding-bottom: 50px;
  }
  .content-certificaciones h3
  {
    color: #1C1C1C;	
    font-size: 24px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 28px;
    margin-bottom: 20px;
  }
  .content-certificaciones a{
    color: #1C1C1C;	
    font-size: 14px;	
    font-weight: 500;	
    line-height: 16px;	
    text-align: center;
  }
  .content-certificaciones p{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .border-bottom-1{
    border-bottom: 1px solid #D4D4D4;
  }
  /* -------------------------- Intranet ------------------------- */
  .background-black{
    background-color: #000000
  }
  header.Intranet .nav-item{
    color: #fff;
    display: flex;
  }
  header.Intranet i{
    font-size: 20px;
  }
  header.Intranet .nav-link {
    padding: 23px 1rem;
  }
  header.Intranet .navbar-brand{
    padding-top: 23px;
    padding-bottom: 23px;
  }
  header.Intranet .navbar-brand img{
    width: 250px;
  }

  header.Intranet .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: #FFFFFF;
    background: transparent;
    border: 0;
    align-self: center
  }
  /* ---------------------- TABLE ------------------- */
  .table-background.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFF;
}
.table-background{
  color: #000;
  background-color: #F5F5F5;
}

.px-15{
  padding-top: 15px;
  padding-bottom: 15px;
}
.align-self-center{
  align-self: center;
}
.justify-content-between{
  justify-content: space-between;
}
.content-table .modal-dialog a.btn:not([href]):not([tabindex]){
  color: #000
}
.info-product img{
  width: 80px;
  height: 50px;
}


.table .linkMore{
  border: 0;	
  font-family: "Roboto";
  line-height: 15px;
  padding: 0;
  border-radius: 0;
  border-bottom: 3px solid #F51A0E;
  background-color: transparent;
  font-weight: 800;
}

.modal-body .md-form{
  margin-top: 2px;
}
.modal-body .label{
  margin-bottom: 0;
}

.info-product{
  font-size: 16px;
}
.content-table .modal-content .tab-pane {
  padding-top: 0;
}
.modal-footer{
  border-top: 0;
}
.iconopdf{
  margin-bottom: 0;
  margin-right: 15px;
  width: 400px;
  text-align: left;
  display: flex;
  align-items: center;
}
.iconopdf.limit{
  width: auto;
  text-align: center;
  margin-right: 0;
}
.iconopdf.productEdit{
  width: 300px;;
  text-align: center;
  margin-right: 0;
}
.iconopdf span{
  line-height: 18px;
}
.showImage{
  display: block;
}
.noneImage{
  display: none;
}
.modal-footer .btn{
  color: #fff;
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}

.addbtn button{
  margin-left: auto;
}

.input-file-container,
.input-file-containers {
position: absolute;
top: 0;
right: 0;
z-index: 2;
} 
.input-file-container{
  position: relative;
  margin: .375rem;
} 
.input-file-trigger,
.input-file-triggers,
.btnDelete {
  display: block;
  padding: 0;
  background:transparent;
  font-weight: 900;
  font-size: 14px;
  transition: all .4s;
  cursor: pointer;
  border-bottom: 3px solid #F51A0E;
}
.btnDelete{
  border-top: 0;
  border-right: 0;
  border-left: 0
}
.modalNoticia .input-file-trigger,
.modalNoticia .input-file-triggers {
  display: block;
  padding: 0;
  background:transparent;
  font-weight: 900;
  z-index: 20000000;
  transition: all .4s;
  cursor: pointer;
  border-bottom:0;
}

label.input-file-triggers img{
  width: 20px;
}


.input-file,
.input-files {
  position: absolute;
  top: 0; left: 0;
  opacity: 0;
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.file-return {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.file-return:not(:empty) {
  margin: 1em 0;
}
.file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}
.file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}

.test-kit .card-body{
  background-color: #F5F5F5;
  color: #000
}
.test-kit .card-body .card-text{
  color: #000;
  margin-bottom: 50px;
  font-size: 14px;
}
.test-kit .btn{
  box-shadow: unset;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: auto;
}
.modal-content .nav{
  border-bottom: 2px solid #f5f5f5;
}
.nav-pills .nav-link.active{
  color: #000;
  font-weight: bold;
  border-bottom: 3px solid #F51A0E;
  background-color: transparent
}
.nav-pills .nav-link{
  border-radius: 0px
}

.tab-pane .md-form.product input[type=text]:focus:not([readonly]),
.modal-content .modal-body input[type=text]:focus:not([readonly]){
  box-shadow: none;
}
.table .thead{
  background-color: #EDEDED;
}
table.table thead th{
  border:1px solid #ddd;
}
.notShow{
  display: none
}
.card-deck{
  padding: 5px
}
.card-deck .card,
.accordion .card{
  box-shadow: none;
}
.padding-5{
  padding: 5px
}
}
@media only screen and (min-width: 576px) {
  .card-deck .card.size{
    flex: unset;
    flex-basis: 44%
  }
}
@media only screen and (min-width: 992px) {
  .notShow{
    display: block
  }
  .card-deck{
    padding: 0
  }
    .card-deck .card.size{
      flex: unset;
      flex-basis: 30%
    }
    /* ------------------------ section - certificacion--------------  */
    .img-content{
      width: 300px;
      height: 200px;
      border: 1px solid #e6e6e6;
      box-shadow: 1px 1px 3px 1px rgba(0,0,0,.04);
    }
    .img-content img{
      width: 100%
    }
    .pt-25{
      padding-top: 50px;
    }
    .pb-25{
      padding-bottom: 50px;
    }
    .content-certificaciones h3,
    .titleMain{
      color: #1C1C1C;	
      font-size: 24px;	
      margin-top: 18px;
      font-weight: 600;	
      line-height: 28px;
      margin-bottom: 20px;
    }
    .content-certificaciones a{
      color: #1C1C1C;	
      font-size: 14px;	
      font-weight: 500;	
      line-height: 16px;	
      text-align: center;
    }
    .content-certificaciones p,
    .history p{
      color: #1C1C1C;	
      font-size: 16px;	
      line-height: 26px;
      margin-bottom: 0;
      font-weight: 400;
    }
    .border-bottom-1{
      border-bottom: 1px solid #D4D4D4;
    }
    /* -------------------------- Intranet ------------------------- */
    .background-black{
      background-color: #000000
    }
    header.Intranet .nav-item{
      color: #fff;
      display: flex;
    }
    header.Intranet i{
      font-size: 20px;
    }
    header.Intranet .nav-link {
      padding: 23px 1rem;
    }
    header.Intranet .navbar-brand{
      padding-top: 23px;
      padding-bottom: 23px;
    }
    header.Intranet .navbar-brand img{
      width: 250px;
    }
  
    header.Intranet .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
      color: #FFFFFF;
      background: transparent;
      border: 0;
      align-self: center
    }
    /* ---------------------- TABLE ------------------- */
    .table-background.table-striped tbody tr:nth-of-type(odd) {
      background-color: #FFF;
  }
  .table-background{
    color: #000;
    background-color: #F5F5F5;
  }

  .px-15{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .align-self-center{
    align-self: center;
  }
  .justify-content-between{
    justify-content: space-between;
  }
  .content-table .modal-dialog a.btn:not([href]):not([tabindex]){
    color: #000
  }
  .info-product img{
    width: 80px;
    height: 50px;
  }


  .table .linkMore{
    border: 0;	
    font-family: "Roboto";
    line-height: 15px;
    padding: 0;
    border-radius: 0;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent;
    font-weight: 800;
  }

  .modal-body .md-form{
    margin-top: 2px;
  }
  .modal-body .label{
    margin-bottom: 0;
  }

  .info-product{
    font-size: 16px;
  }
  .content-table .modal-content .tab-pane {
    padding-top: 0;
  }
  .modal-footer{
    border-top: 0;
  }
  .iconopdf{
    margin-bottom: 0;
    margin-right: 15px;
    width: 400px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .iconopdf.limit{
    width: auto;
    text-align: center;
    margin-right: 0;
  }
  .iconopdf.productEdit{
    width: 300px;;
    text-align: center;
    margin-right: 0;
  }
  .iconopdf span{
    line-height: 18px;
  }
  .showImage{
    display: block;
  }
  .noneImage{
    display: none;
  }
  .modal-footer .btn{
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-size: 16px;
  }

  .addbtn button{
    margin-left: auto;
  }

 .input-file-container,
 .input-file-containers {
  position: absolute;
  top: 0;
  right: 0;
  } 
  .input-file-container{
    position: relative;
    margin: .375rem;
  } 
  .input-file-trigger,
  .input-file-triggers,
  .btnDelete {
    display: block;
    padding: 0;
    background:transparent;
    font-weight: 900;
    font-size: 14px;
    transition: all .4s;
    cursor: pointer;
    border-bottom: 3px solid #F51A0E;
  }
  .btnDelete{
    border-top: 0;
    border-right: 0;
    border-left: 0
  }
  .modalNoticia .input-file-trigger,
  .modalNoticia .input-file-triggers {
    display: block;
    padding: 0;
    background:transparent;
    font-weight: 900;
    z-index: 20000000;
    transition: all .4s;
    cursor: pointer;
    border-bottom:0;
  }

  label.input-file-triggers img{
    width: 20px;
  }


  .input-file,
  .input-files {
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    padding: 0;
    z-index: 10;
    cursor: pointer;
  }

  .file-return {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .file-return:not(:empty) {
    margin: 1em 0;
  }
  .file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
  }
  .file-return:not(:empty):before {
    content: "Selected file: ";
    font-style: normal;
    font-weight: normal;
  }

  .test-kit .card-body{
    background-color: #F5F5F5;
    color: #000
  }
  .test-kit .card-body .card-text{
    color: #000;
    margin-bottom: 50px;
    font-size: 14px;
  }
  .test-kit .btn{
    box-shadow: unset;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: auto;
  }
  .modal-content .nav{
    border-bottom: 2px solid #f5f5f5;
  }
  .nav-pills .nav-link.active{
    color: #000;
    font-weight: bold;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent
  }
  .nav-pills .nav-link{
    border-radius: 0px
  }

  .tab-pane .md-form.product input[type=text]:focus:not([readonly]),
  .modal-content .modal-body input[type=text]:focus:not([readonly]){
    box-shadow: none;
  }
  .table .thead{
    background-color: #EDEDED;
  }
  table.table thead th{
    border:1px solid #ddd;
  }
}

@media only screen and (min-width: 1024px) {
  /* --------------------------------- HEADER ------------------------------  */
  /* ------------------------- slide nav ----------------- */

  
}
        
@media only screen and (min-width: 1200px) {
  /* --------------------------------- HEADER ------------------------------  */
  /* ------------------------- slide nav ----------------- */
 /* --------------- Header ----------------- */
 header 
 .navbar {
   padding: 0 0 0 100px;
 }

 /* ----------------- Seccion - 1 ------------------- */
 .lines{
   height: 151px;
 }
 .columns .lines .img-line {
   height: 250px;
   margin-top: -101px;
 }
 .content-welcome .columns .lines a {
   font-size: 18px;
 }
 .info-uniserve{
   width: 590px;
   height: 450px;
   padding: 177px 0 0 100px;
 }
 .info-uniserve .text2{
   font-size: 40px;
   line-height: 44px;
 }

}
          