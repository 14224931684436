@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 320px) {


  /* ------------------------ section - certificacion--------------  */
  .img-content{
    width: 300px;
    height: 200px;
    border: 1px solid #e6e6e6;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,.04);
  }
  .img-content img{
    width: 100%
  }
  .pt-25{
    padding-top: 50px;
  }
  .pb-25{
    padding-bottom: 50px;
  }
  .content-certificaciones h3
  {
    color: #1C1C1C;	
    font-size: 24px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 28px;
    margin-bottom: 20px;
  }
  .content-certificaciones a{
    color: #1C1C1C;	
    font-size: 14px;	
    font-weight: 500;	
    line-height: 16px;	
    text-align: center;
  }
  .content-certificaciones p{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .border-bottom-1{
    border-bottom: 1px solid #D4D4D4;
  }
  /* -------------------------- Intranet ------------------------- */
  .background-black{
    background-color: #000000
  }
  header.Intranet .nav-item{
    color: #fff;
    display: flex;
  }
  header.Intranet i{
    font-size: 20px;
  }
  header.Intranet .nav-link {
    padding: 23px 1rem;
  }
  header.Intranet .navbar-brand{
    padding-top: 23px;
    padding-bottom: 23px;
  }
  header.Intranet .navbar-brand img{
    width: 250px;
  }

  header.Intranet .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: #FFFFFF;
    background: transparent;
    border: 0;
    align-self: center
  }
  /* ---------------------- TABLE ------------------- */
  .table-background.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFF;
}
.table-background{
  color: #000;
  background-color: #F5F5F5;
}

.px-15{
  padding-top: 15px;
  padding-bottom: 15px;
}
.align-self-center{
  align-self: center;
}
.justify-content-between{
  justify-content: space-between;
}
.content-table .modal-dialog a.btn:not([href]):not([tabindex]){
  color: #000
}
.info-product img{
  width: 80px;
  height: 50px;
}


.table .linkMore{
  border: 0;	
  font-family: "Roboto";
  line-height: 15px;
  padding: 0;
  border-radius: 0;
  border-bottom: 3px solid #F51A0E;
  background-color: transparent;
  font-weight: 800;
}

.modal-body .md-form{
  margin-top: 2px;
}
.modal-body .label{
  margin-bottom: 0;
}

.info-product{
  font-size: 16px;
}
.content-table .modal-content .tab-pane {
  padding-top: 0;
}
.modal-footer{
  border-top: 0;
}
.iconopdf{
  margin-bottom: 0;
  margin-right: 15px;
  width: 400px;
  text-align: left;
  display: flex;
  align-items: center;
}
.iconopdf.limit{
  width: auto;
  text-align: center;
  margin-right: 0;
}
.iconopdf.productEdit{
  width: 300px;;
  text-align: center;
  margin-right: 0;
}
.iconopdf span{
  line-height: 18px;
}
.showImage{
  display: block;
}
.noneImage{
  display: none;
}
.modal-footer .btn{
  color: #fff;
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}

.addbtn button{
  margin-left: auto;
}

.input-file-container,
.input-file-containers {
position: absolute;
top: 0;
right: 0;
z-index: 2;
} 
.input-file-container{
  position: relative;
  margin: .375rem;
} 
.input-file-trigger,
.input-file-triggers,
.btnDelete {
  display: block;
  padding: 0;
  background:transparent;
  font-weight: 900;
  font-size: 14px;
  -webkit-transition: all .4s;
  transition: all .4s;
  cursor: pointer;
  border-bottom: 3px solid #F51A0E;
}
.btnDelete{
  border-top: 0;
  border-right: 0;
  border-left: 0
}
.modalNoticia .input-file-trigger,
.modalNoticia .input-file-triggers {
  display: block;
  padding: 0;
  background:transparent;
  font-weight: 900;
  z-index: 20000000;
  -webkit-transition: all .4s;
  transition: all .4s;
  cursor: pointer;
  border-bottom:0;
}

label.input-file-triggers img{
  width: 20px;
}


.input-file,
.input-files {
  position: absolute;
  top: 0; left: 0;
  opacity: 0;
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.file-return {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.file-return:not(:empty) {
  margin: 1em 0;
}
.file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}
.file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}

.test-kit .card-body{
  background-color: #F5F5F5;
  color: #000
}
.test-kit .card-body .card-text{
  color: #000;
  margin-bottom: 50px;
  font-size: 14px;
}
.test-kit .btn{
  box-shadow: unset;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: auto;
}
.modal-content .nav{
  border-bottom: 2px solid #f5f5f5;
}
.nav-pills .nav-link.active{
  color: #000;
  font-weight: bold;
  border-bottom: 3px solid #F51A0E;
  background-color: transparent
}
.nav-pills .nav-link{
  border-radius: 0px
}

.tab-pane .md-form.product input[type=text]:focus:not([readonly]),
.modal-content .modal-body input[type=text]:focus:not([readonly]){
  box-shadow: none;
}
.table .thead{
  background-color: #EDEDED;
}
table.table thead th{
  border:1px solid #ddd;
}
.notShow{
  display: none
}
.card-deck{
  padding: 5px
}
.card-deck .card,
.accordion .card{
  box-shadow: none;
}
.padding-5{
  padding: 5px
}
}
@media only screen and (min-width: 576px) {
  .card-deck .card.size{
    flex: unset;
    flex-basis: 44%
  }
}
@media only screen and (min-width: 992px) {
  .notShow{
    display: block
  }
  .card-deck{
    padding: 0
  }
    .card-deck .card.size{
      flex: unset;
      flex-basis: 30%
    }
    /* ------------------------ section - certificacion--------------  */
    .img-content{
      width: 300px;
      height: 200px;
      border: 1px solid #e6e6e6;
      box-shadow: 1px 1px 3px 1px rgba(0,0,0,.04);
    }
    .img-content img{
      width: 100%
    }
    .pt-25{
      padding-top: 50px;
    }
    .pb-25{
      padding-bottom: 50px;
    }
    .content-certificaciones h3,
    .titleMain{
      color: #1C1C1C;	
      font-size: 24px;	
      margin-top: 18px;
      font-weight: 600;	
      line-height: 28px;
      margin-bottom: 20px;
    }
    .content-certificaciones a{
      color: #1C1C1C;	
      font-size: 14px;	
      font-weight: 500;	
      line-height: 16px;	
      text-align: center;
    }
    .content-certificaciones p,
    .history p{
      color: #1C1C1C;	
      font-size: 16px;	
      line-height: 26px;
      margin-bottom: 0;
      font-weight: 400;
    }
    .border-bottom-1{
      border-bottom: 1px solid #D4D4D4;
    }
    /* -------------------------- Intranet ------------------------- */
    .background-black{
      background-color: #000000
    }
    header.Intranet .nav-item{
      color: #fff;
      display: flex;
    }
    header.Intranet i{
      font-size: 20px;
    }
    header.Intranet .nav-link {
      padding: 23px 1rem;
    }
    header.Intranet .navbar-brand{
      padding-top: 23px;
      padding-bottom: 23px;
    }
    header.Intranet .navbar-brand img{
      width: 250px;
    }
  
    header.Intranet .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
      color: #FFFFFF;
      background: transparent;
      border: 0;
      align-self: center
    }
    /* ---------------------- TABLE ------------------- */
    .table-background.table-striped tbody tr:nth-of-type(odd) {
      background-color: #FFF;
  }
  .table-background{
    color: #000;
    background-color: #F5F5F5;
  }

  .px-15{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .align-self-center{
    align-self: center;
  }
  .justify-content-between{
    justify-content: space-between;
  }
  .content-table .modal-dialog a.btn:not([href]):not([tabindex]){
    color: #000
  }
  .info-product img{
    width: 80px;
    height: 50px;
  }


  .table .linkMore{
    border: 0;	
    font-family: "Roboto";
    line-height: 15px;
    padding: 0;
    border-radius: 0;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent;
    font-weight: 800;
  }

  .modal-body .md-form{
    margin-top: 2px;
  }
  .modal-body .label{
    margin-bottom: 0;
  }

  .info-product{
    font-size: 16px;
  }
  .content-table .modal-content .tab-pane {
    padding-top: 0;
  }
  .modal-footer{
    border-top: 0;
  }
  .iconopdf{
    margin-bottom: 0;
    margin-right: 15px;
    width: 400px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .iconopdf.limit{
    width: auto;
    text-align: center;
    margin-right: 0;
  }
  .iconopdf.productEdit{
    width: 300px;;
    text-align: center;
    margin-right: 0;
  }
  .iconopdf span{
    line-height: 18px;
  }
  .showImage{
    display: block;
  }
  .noneImage{
    display: none;
  }
  .modal-footer .btn{
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-size: 16px;
  }

  .addbtn button{
    margin-left: auto;
  }

 .input-file-container,
 .input-file-containers {
  position: absolute;
  top: 0;
  right: 0;
  } 
  .input-file-container{
    position: relative;
    margin: .375rem;
  } 
  .input-file-trigger,
  .input-file-triggers,
  .btnDelete {
    display: block;
    padding: 0;
    background:transparent;
    font-weight: 900;
    font-size: 14px;
    -webkit-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    border-bottom: 3px solid #F51A0E;
  }
  .btnDelete{
    border-top: 0;
    border-right: 0;
    border-left: 0
  }
  .modalNoticia .input-file-trigger,
  .modalNoticia .input-file-triggers {
    display: block;
    padding: 0;
    background:transparent;
    font-weight: 900;
    z-index: 20000000;
    -webkit-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    border-bottom:0;
  }

  label.input-file-triggers img{
    width: 20px;
  }


  .input-file,
  .input-files {
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    padding: 0;
    z-index: 10;
    cursor: pointer;
  }

  .file-return {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .file-return:not(:empty) {
    margin: 1em 0;
  }
  .file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
  }
  .file-return:not(:empty):before {
    content: "Selected file: ";
    font-style: normal;
    font-weight: normal;
  }

  .test-kit .card-body{
    background-color: #F5F5F5;
    color: #000
  }
  .test-kit .card-body .card-text{
    color: #000;
    margin-bottom: 50px;
    font-size: 14px;
  }
  .test-kit .btn{
    box-shadow: unset;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: auto;
  }
  .modal-content .nav{
    border-bottom: 2px solid #f5f5f5;
  }
  .nav-pills .nav-link.active{
    color: #000;
    font-weight: bold;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent
  }
  .nav-pills .nav-link{
    border-radius: 0px
  }

  .tab-pane .md-form.product input[type=text]:focus:not([readonly]),
  .modal-content .modal-body input[type=text]:focus:not([readonly]){
    box-shadow: none;
  }
  .table .thead{
    background-color: #EDEDED;
  }
  table.table thead th{
    border:1px solid #ddd;
  }
}

@media only screen and (min-width: 1024px) {
  /* --------------------------------- HEADER ------------------------------  */
  /* ------------------------- slide nav ----------------- */

  
}
        
@media only screen and (min-width: 1200px) {
  /* --------------------------------- HEADER ------------------------------  */
  /* ------------------------- slide nav ----------------- */
 /* --------------- Header ----------------- */
 header 
 .navbar {
   padding: 0 0 0 100px;
 }

 /* ----------------- Seccion - 1 ------------------- */
 .lines{
   height: 151px;
 }
 .columns .lines .img-line {
   height: 250px;
   margin-top: -101px;
 }
 .content-welcome .columns .lines a {
   font-size: 18px;
 }
 .info-uniserve{
   width: 590px;
   height: 450px;
   padding: 177px 0 0 100px;
 }
 .info-uniserve .text2{
   font-size: 40px;
   line-height: 44px;
 }

}
          
@media only screen and (min-width: 320px) {

  .showNavItem{
    display: none
  }

  .main .navbar-brand 
  img{
    width: 146px;
  }

  .main .navbar-brand{
    margin-left: 20px;
  }
  .main .navbar-nav {
    display: flex;
    text-align: center;
  }
  
  /* ------------------------- slide nav ----------------- */
  .main #hambmenu {
    position: relative;
    width: 27px;
    height: 45px;
    margin-right: 5px;
    float: left;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    color: #000

  }
  .main #hambmenu span {
    position: absolute;
    height: 2px;
    vertical-align: middle;
    margin: 10px 5px;
    background: #000;
    border-radius: 8px;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    padding: 0px;
  }
  
  .main .hambclicker {
    content: "";
    height: 48px;
    width: 55px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .main #hambmenu 
  span:nth-child(1) {
    top: 5px;
  }
  
  .main #hambmenu span:nth-child(2), 
  .main #hambmenu span:nth-child(3) {
    top: 10px;
  }
  
  .main #hambmenu span:nth-child(4) {
    top: 15px;
  }
  
  .main #hambmenu.isopen span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
  }
  
  .main #hambmenu.isopen span:nth-child(2) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: #FFFFFF;
    z-index: 10;
  }
  
  .main #hambmenu.isopen span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    background-color: #FFFFFF;
    z-index: 10;
  }
  
  .main #hambmenu.isopen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
  }
  
  .main #menu {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100vh;
    width: 100%;
    background-color: rgba(28,28,28,0.85);;
    -webkit-transform: translateX(-100%);;
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .main #menu ul {
    margin-top: 69px;
    padding: 0px;
    list-style-type: none;
    text-align: left;
  }
  .main #menu li {
    padding: 30px 0 20px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }
  .main #menu li:last-child {
    border-bottom: none;
  }
  
  .main #menu.isopen {
    -webkit-transform: none;
            transform: none;
  }
  
  .main .nav-lateral{
    padding-left: 0px;
    list-style: none;
  }
  .main .nav-lateral a{
    text-decoration: none;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #FFFFFF;	
    font-size: 20px;	
    font-weight: bold;	
    line-height: 24px;
  }
  .main .content-nav-lateral{
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 576px) {
  .main .navbar-brand{
    margin-left: 75px;
  }
  .main #menu li {
    padding: 30px 0 20px 50px;
  }
}
@media only screen and (min-width: 992px) {
  .main .navbar-brand{
    margin-left: 20px;
  }
  header.main .navbar {
    padding-left: 80px;
    padding-right: 1rem;
  }
  .main .navbar-brand 
  img{
    width: 186px;
    height: 23px;
  }
  .main .navbar-nav .nav-item {
    padding: 27px 23px;
    line-height: 3
  }
  header.main .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    font-size: 15px;
    color: #1C1C1C;
    font-weight: bold;
    position: relative;
    height: 20px;
    line-height: 1.5;
    padding: 0;
    padding-right: .0;
    padding-left: .0;
  }
  .main .nav-item .inactive hr{
    position: absolute;
    margin: 0;
    bottom: -3px;
    width: 100%;
    border: unset
  }
  .main .nav-item .inactive hr:before{
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    bottom: -2px;
    border-top:4px solid rgb(245, 26, 14);
    -webkit-transition: all 0.4s 0.3s ease-in-out;
    transition: all 0.4s 0.3s ease-in-out;
  }
  .main .navbar.navbar-dark .navbar-nav .nav-item.active>.nav-link:hover,
  .main .navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover{
    color: #000;
  }
  .main .nav-item .inactive:hover hr:before{
    width: 100%;
  }
  .main .underLine hr{
    margin: 0;
    position: absolute;
    bottom: -5px;
    width: 100%;
    border-top: 4px solid #F51A0E;
  }
  /* ------------------------- slide nav ----------------- */
  .main .menu-nav{
    display: none;
  }
  .main .isopen{
    display: none;
  }
  .showNavItem{
    display: flex;
  }
  .main #hambmenu span,.hambclicker {
    display: none;
  }
}
body{
  font-family: "Roboto"
}
.navbar{
  padding: 0;
}
.border-translate{
  border-right: 1px solid #DFDFDF;
  border-left: 1px solid #DFDFDF;
}

.border-col{
  border: 1px solid rgba(255,255,255,0.5);
}
.border-col-4{
  border:1px solid #DFDFDF;
}

.content-add-image{
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-add-image .btn{
  margin: 0;
  border-radius: 50px;
}
.content-table .modal-dialog .modal-body .btn{
  border: 1px solid #1C1C1C;
  border-radius: 50px;
  padding-left: .5rem;
  padding-right: .5rem;
}
@media only screen and (min-width: 320px) {
  .historyContent h4{
    color: #1C1C1C;	
    font-size: 14px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }

  .infoHistory{
    position: absolute;
    top: 0;
    padding: 17px;
    left: 0;
    z-index: 2;
  }

  .widthUniservice{
    width: 152px;
    height: 29px;
  }
  .widthUniLatina{
    width: 136px;
    height: 32px;
  }
  .widthMarin{
    width: 70px;
    height: 73px;
  }
  .widthTeco{
    width: 104px;
    height: 61px;
  }
  .widthBlutec{
    width: 160px;
    height: 60px;
  }
  .widthChemo{
    width: 136px;
    height: 32px;
  }
  .widthNavadan{
    width: 152px;
    height: 29px;
  }
  .widthMarichem{
    width: 198px;
    height: 35px;
  }
  .widthWorld{
    width: 152px;
    height: 55px;
  }
  .marca{
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    align-self: center;
  }
  .historyContent{
    width: 100%;
    background-color: #F5F5F5;
    position: relative;
  }
  .imgHistory{
    height: 1100px;
    background-image: url(/static/media/Amazonas.49277e9c.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    -webkit-filter: opacity(0.2) grayscale(100%);
            filter: opacity(0.2) grayscale(100%);
  }

  .titleMain{
    color: #1C1C1C;	
    font-size: 18px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center
  }
  .sign{
    background: #F5F5F5;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 10px 15px;
  }
  .sign h5{
    color: #1C1C1C;	
    margin-top: 22px;
    font-weight: 400;	
    text-align: center;
    font-size: 16px;	
    line-height: 19px;
  }
  .history p{
    color: #1C1C1C;	
    font-size: 14px;	
    line-height: 17px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .linkDown{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 5px
  }
  .linkDown a{
    display: flex;
    color: #1C1C1C
  }
  .linkDown a span{
    margin-right: 5px;
    align-self: center;
    line-height: 15px;
  }
  .worldIcon{
    width: 25px
  }

  .congrat h3{
    color: #1C1C1C;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .congrat p,.congrat p a{
    color: #1C1C1C;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .congrat p a{
    text-decoration: underline
  }
  .infoHistory hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0;
    border-top: 2px solid #bbb;
  }
}
@media only screen and (min-width: 576px) {
  .imgHistory{
    height: 950px;
  }
  .infoHistory{
    padding: 40px 75px;
  }
}

@media only screen and (min-width: 992px) {
  .imgHistory{
    height: 870px;
  }

  .linkDown{
    padding: 0
  }
  .worldIcon{
    width: 50px
  }
  .infoHistory{
    position: absolute;
    top: 0;
    width: 100%;
    padding: 65px 100px;
    left: 0;
    z-index: 2;
  }
  .sign{
    padding: 40px 50px;
  }
  .history h4{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .congrat h3{
    font-size: 16px;
  }
  .congrat p,.congrat p a{
    font-size: 16px;
  }
  .history p{
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .titleMain{
    color: #1C1C1C;	
    font-size: 24px;	
    margin-top: 18px;
    font-weight: 600;	
    line-height: 28px;
    margin-bottom: 20px;
  }
  .sign h5{
    text-align: left;
    color: #1C1C1C;	
    margin-top: 18px;
    font-weight: 400;	
    margin-bottom: 20px;
    font-size: 16px;	line-height: 19px;
  }
  .sign h4{
    color: #1C1C1C;	
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
    font-weight: 600;
  }

  /* .widthTeco{
    width: 127px;
    height: 73px;
  }
  .widthBlutec{
    width: 174px;
    height: 66px;
  }
  .widthChemo{
    width: 174px;
    height: 40px;
  }
  .widthNavadan{
    width: 186px;
    height: 37px;
  }
  .widthMarichem{
    width: 265px;
    height: 47px;
  }
  .widthWorld{
    width: 172px;
    height: 61px;
  } */
  .marca{
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 400
  }
  .marca div{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;    
  }

  .marca .lat-left{
    justify-content: left;
  }

  .marca .norm{
    justify-content: center;
  }
  .marca .lat-lg-left{
    justify-content: left
  }
  .marca .lat-lg-center{
    justify-content: center
  }

  .content-img-marca{
    text-align: left;
  }

  .historyContent{
    border-radius: 20px;
  }

  .linkDown{
    margin-top: 50px;
  }
  .linkDown a{
    align-content: center;
  }
  .linkDown a span{
    margin-right: 5px;
  }
}

@media only screen and (min-width: 1024px) {

  .widthUniservice{
    width: 225px;
    height: 50px;
  }
  .widthUniLatina{
    width: 244px;
    height: 40px;
  }
  .widthMarin{
    width: 70px;
    height: 73px;
  }
  .widthTeco{
    width: 127px;
    height: 73px;
  }

  .widthBlutec{
    width: 174px;
    height: 66px;
  }
  .widthChemo{
    width: 174px;
    height: 40px;
  }
  .widthNavadan{
    width: 186px;
    height: 37px;
  }
  .widthMarichem{
    width: 265px;
    height: 47px;
  }
  .widthWorld{
    width: 172px;
    height: 61px;
  }


}
@media only screen and (min-width: 1200px) {
  .marca .lat-xl-left{
    justify-content: left
  }
  .marca .lat-xl-center{
    justify-content: center
  }
  .infoHistory{

    padding: 65px 200px;

  }
}
@media only screen and (min-width: 320px) {
.first-section{
  position: relative
}

.content-welcome{
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 73px;
  padding: 0 20px;
  left: 0;
  right: 0;
}
.content-welcome .lines .nav-link{
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.lines-specific{
  width: 100%;
  height: 100%;
}

.lines-specific p{
 opacity: 1;
}
.lines{
  position: relative;
  height: 110px;
  z-index: 0;
}
.fileDownload{
  position: absolute;
  z-index: 50;
  opacity: 0;
  bottom: 20px;
  align-items: center;
  -webkit-transition: all 1s;
  transition: all 1s;
  justify-content: center;
  display: flex;
}
.fileDownload img{
  width: 50px;
}

.content-welcome .columns .lines:hover .img-line{
  z-index: -5;
  max-height: 300px;
}
.content-welcome .columns .lines:hover .nav-link{
  z-index: 10000;
}
.content-welcome .columns .lines .lines-specific:hover p{
  opacity: 0;
}
.content-welcome .columns .lines .lines-specific:hover .fileDownload{
  opacity: 1;
  -webkit-transition: all 1s;
  transition: all 1s;
  color: #fff
}

.imgInfo-content{
  width: 100%;
  height: calc(100vh - 74px);
  background: url(/static/media/ship.bd3deba7.jpg) no-repeat 0;
  background-size: cover;
}
.info-uniserve{
  width: 280px;
  height: calc(100% - 183px);
  display: flex;
  align-items: center;
  padding:0 20px 0;
  color: #fff;
}

.info-uniserve .text1{
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 13px
}
.info-uniserve .text2{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.content-welcome .columns .lines .img-line {
  display: none;
}
}
@media only screen and (min-width: 576px) {
  .info-uniserve{
    margin-left: 55px;
  }
  .content-welcome{
    width: 100%;
    z-index: 1;
    bottom: 0;
    padding: 0
  }
  /* .imgInfo-content{
    background-position-x: 0px;
    background-position-y: 0px
  } */
}
@media only screen and (min-width: 992px) {

  .imgInfo-content {
    -webkit-transition: all 1s;
    transition: all 1s 
  }
  .fileDownload{
    width: 100%;
    height: 40px;
    bottom: 20px;
    color:#fff;
  }
  .fileDownload img{
    width: 50px;
    margin-right: 10px;
    margin-left: 15px;
  }
  .oscurecer{
    background-color: rgba(0,0,0,0.8);
    -webkit-filter:brightness(0.7);
            filter:brightness(0.7);
  }
  .content-welcome .lines .nav-link{
    text-align: left;
    justify-content: end;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 27px;
  }
  .content-welcome .lines .lines-specific p{
    text-align: left;
    justify-content: end;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 27px;
  }
  .content-welcome .lines p.nav-link{
    font-size: 18px;
    margin-bottom: 0;
    opacity: 1;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

  .content-welcome:hover + .imgInfo-content{
    -webkit-filter:brightness(0.6);
            filter:brightness(0.6);
    z-index: 1000;
  }

  .info-uniserve{
    width: 450px;
    height: calc(100% - 181px);
    padding: 0 0 0 100px;
    margin: 0;
  }
  .info-uniserve .text1{
    font-size: 18px;
    margin-bottom: 18px
  }
  .info-uniserve .text2{
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
  }

  .content-welcome .columns .lines .img-line {
    display: block;
    position: absolute;
    z-index: -10;
    filter: alpha(opacity=30);
    bottom: 0;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;  
    max-height: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .lines{
    height: 151px;
  }
  .columns .lines .img-line {
    height: 250px;
    margin-top: -101px;
  }
  .content-welcome .columns .lines a {
    font-size: 18px;
  }
  .info-uniserve{
    width: 590px;
    padding: 0 0 0 100px;
  }
  .info-uniserve .text2{
    font-size: 40px;
    line-height: 44px;
  }

}

@media only screen and (min-width: 1500px) {
  .lines{
    height: 181px;
  }

  .columns .lines .img-line {
    height: 300px;
    margin-top: -120px;
  }

  .columns .lines a {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 320px) {

  /* ------------ NOTICIAS ------------- */
  .background-general{
    background-color: #F5F5F5;
  }
  
  .border-contentImage{
    border: unset;
  }
  .border-bottom{
    border-bottom: 1px solid #D4D4D4;
  }
  .section-slide{
    padding-top: 50px;
    padding-bottom: 90px;
  }
  .section-slide h3{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 20px;	
    font-weight: bold;	
    margin-bottom: 0;
    margin-left: 5px;
  }
  .content-img{
    height: 184px;	
    width: 100%;	
    border: 1px solid #DFDFDF;
    align-self: center;
    margin: 0 auto;
  }

  .content-btn{
    display: none;
  }
  .content-info{
    padding-top: 25px;
    padding-bottom: 11px;
  }
  .content-info p,
  .content-info h4,
  .section-slide h3{
    color: #1C1C1C;	
  }
  .content-info h4{
    font-family: "Roboto";	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 16px;
    margin-bottom: 11px;
  }
  .content-info p{
    font-family: "Roboto";	
    font-size: 14px;	
    line-height: 20px;
    font-weight: 400;
  }
  
  .content-info button{
    border: 0;	
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 0;
    padding: 5px 0 0px;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent;
    font-weight: 800;
    margin-top: 5px
  }
  
  .noticiasMas{
    height: 52px;	
    width: 231.83px;
    font-size: 18px;
    border: 1px solid #AAAAAA;	
    border-radius: 26px;
    margin: 0 30px;
    background: #FFFFFF;
    color: #1C1C1C;
    font-weight: bold;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .carousel{
    padding: 0 5px;
  }
  .carousel__slide{
    padding-bottom: 25px;
    padding-top: 15px;
  }
  .btn-next{
    color: #1C1C1C;
    border-bottom:3px solid #F51A0E;
    line-height: 0;
    font-weight: 500
  }
  .section-certificacion .card{
    box-shadow: none;
    padding:10px;

  }
  .section-certificacion .card[class*=border] {
    border: none;
  }
  .section-certificacion .card .card-img-top{
    width: 350px;
  }
  .section-certificacion .card .card-body{
    padding: 15px 0;
  }
  .section-certificacion .card .card-text{
    color: #1C1C1C
  }

  .content-certificaciones{
    padding: 20px 20px;
    background: #F5F5F5;
    border-radius: 20px;
  }
  .section-noticia .content-certificaciones img{
    width: 100%;
    margin: 15px auto;
  }
  .section-noticia .images{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .section-noticia .body-info{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 16px;	
    line-height: 26px;
    font-weight: 400;
    width: 100%;
  }
  .section-noticia .body-info a{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 16px;	
    line-height: 26px;
    font-weight: 400;
    width: 100%;
  }
}
  
  @media only screen and (min-width: 576px) {
  
    
  }
  
  @media only screen and (min-width: 768px) {
  
    
  }
  
  @media only screen and (min-width: 992px) {
  
    /* ------------------------ NOTICIAS ----------------- */
    
    .section-slide{
      padding-top: 125px;
      padding-bottom: 190px;
    }
    .section-slide h3{
      font-size: 40px;	
      line-height: 44px;
      margin-bottom: 44px
    }
  
    .item-slide h1 {
      text-transform: uppercase;
    }

    .carousel{
      padding:0
    }
    .prev, .next {
      cursor: pointer;
      width: auto;
      height: 82px;
      width: 82px;;
      padding: 30px 30px;
      font-weight: bold;
      font-size: 18px;
      -webkit-transition: 1s ease;
      transition: 1s ease;
      border: 1px solid #DFDFDF;	
      background-color: #FFFFFF;
      color: #F51A0E;
    }
  
    .content-btn{
      position: absolute;
      z-index: 2;
      bottom: 25px;
      left: 301px;
      display: flex
    }
    .content-info{
      padding-left: 95px;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-right: 10px;
      text-align: left;
    }

    .content-info h4{
      font-size: 20px;	
      line-height: 28px;
      margin-bottom: 9px;
    }
    .content-info p{
      font-size: 16px;	
      line-height: 22px;
      margin-bottom: 0;
    }
 
    .content-img{
      height: 350px;
    } 

    .content-info button{
      position: unset;
    }
    .prev:hover, .next:hover {
      background-color: rgba(0,0,0,0.8);
    }
  
    .noticiasMas{
      margin: 20px auto 0;
    }

    .carousel-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 210px;
    }
    
    .carousel {
      position: relative;
    }
    
    .carousel__slide {
      margin-right: auto;
      margin-left: auto;
      display: none;
      list-style-type: none;
      text-align: center;
    }
    @media (max-width: 991px) {
      .carousel__slide {
        padding-right: 60px;
        padding-left: 60px;
      }
    }
    .carousel__slide--active {
      display: block;
    }
    
    .carousel-slide__content {
      margin-bottom: 19px;
      font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
      font-size: 16px;
    }
    @media (max-width: 991px) {
      .carousel-slide__content {
        font-size: 18px;
      }
    }
    
    .carousel-slide__author,
    .carousel-slide__source {
      font-family: 'Roboto', arial, sans-serif;
      font-size: 14px;
    }
    @media (min-width: 992px) {
      .carousel-slide__author,
      .carousel-slide__source {
        font-size: 16px;
      }
    }
    
    .carousel-slide__source {
      font-style: italic;
      color: #888;
    }
    .section-certificacion .card{
      box-shadow: none;
      padding: 40px 100px;

    }
    .section-certificacion .card[class*=border] {
      border: none;
    }
    .section-certificacion .card .card-img-top{
      width: 350px;
    }
    .section-certificacion .card .card-body{
      padding: 15px 0;
    }
    .section-certificacion .card .card-text{
      color: #1C1C1C
    }

    .content-certificaciones{
      padding: 40px 100px;
      background: #F5F5F5;
      border-radius: 20px;
    }
    .section-noticia .content-certificaciones img{
      width: 500px;
      margin: 15px auto;
    }
    .section-noticia .images{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .section-noticia .body-info{
      color: #1C1C1C;	
      font-family: "Roboto";	
      font-size: 16px;	
      line-height: 26px;
      font-weight: 400;
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 1024px) {

    
  }
          
  @media only screen and (min-width: 1200px) {
    .content-btn{
      left: 391px;
   }

  }
            
@media only screen and (min-width: 320px) {
    .showList {
        display: none;
    }
    footer.page-footer {
        background-color: #EDEDED;
        color: #1C1C1C;
        padding: 45px 0 0;
    }
    .logo-2 {
        width: 231px;
        margin-top: 24px
    }
    .logo-1 {
        margin-top: 15px;
        width: 270px;
    }
    footer.page-footer .option-contact {
        margin-bottom: 15px;
        text-align: center
    }
    footer.page-footer h5{
        text-align: center;
    }
    .popover_parent .drop,
    footer.page-footer .option a,
    footer.page-footer h5,
    .contact h4{
        font-weight: bold;
    }
    footer.page-footer .contact p
     {
        font-weight: 400;
        display: flex;
        flex-direction: column
    }
    .popover_parent .drop,
    footer.page-footer .option a,
    footer.page-footer .contact a,
    footer.page-footer .contact p,
    footer.page-footer h4{
        color: #1C1C1C;
        font-size: 14px;
        line-height: 16px;
    }

    .popover_parent {
        display: none;
    }
    .page-footer .row {
        flex-wrap: wrap-reverse;
    }
    .content-logo {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        padding-bottom: 66px;
    }

    footer.page-footer .footer-copyright{
        overflow: hidden;
        background-color: unset;
        color: #1C1C1C;
        font-family: "Roboto";
        font-size: 10px;
        line-height: 11px;
        text-align: center;
        width: 220px;
        margin: 0 auto;
        font-weight: 500;
        padding-bottom: 18px;
    }
    footer.page-footer .link-footer{
        overflow: hidden;
        background-color: unset;
        color: #1C1C1C;
        font-family: "Roboto";
        line-height: 11px;
        font-size: 10px;
        text-align: center;
        width: 220px;
        margin: 0 auto;
        padding-bottom: 18px;
    }
    footer hr {
        box-sizing: border-box;
        height: 1px;
        width: 89px;
        border: 1px solid #979797;
        margin-top: 0;
    }
}
@media only screen and (min-width: 768px) {
    }
@media only screen and (min-width: 992px) {
    .logo-2 {
      width: 272px
    }
    .logo-1 {
      margin-top: 15px;
      width: 320px
    }
    .content-logo {
        align-items: unset;
    }
    .showList {
        display: block;
    }
    footer.page-footer {
        padding-top: 81px;
    }
    footer.page-footer a {
        color: unset;
    }
    .page-footer .row {
        flex-wrap: wrap;
    }
    .popover_parent {
        position: relative;
        display: inline-block;
    }

    footer.page-footer .option-contact{
        text-align: left;
    }
    footer.page-footer h5{
        text-align: left;
    }
    footer.page-footer h5{
        line-height: 2;
    }
    .popover_parent .fas {
        line-height: 15px;
        margin-left: 8px;
    }

    footer.page-footer .footer-copyright {
        width: unset;
        text-align: right;
        margin-top: 79px;
        padding-right: 38px;
        padding-bottom: 35px;
    }

    .popovers .btn {
        background-color: #F51A0E;
        color: #FFFFFF;
        width: 100%;
        margin: 0;
        box-shadow: unset;
        margin-top: 18px;
    }
    .popovers h3 {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 19px;
    }
    .popovers label {
        color: #666666;
        font-size: 14px;
        line-height: 26px;
    }

    .popover_parent form .form-control {
        height: 48px;
        width: 286px;
        border: 1px solid #AAAAAA;
        border-radius: 0;
    }
    .popover_parent form .form-group {
        margin-bottom: 7px;
    }

    .dropdown-item {
        padding: 0.3rem 0;
        color: #666666;
        font-size: 10px;
        line-height: 26px;
    }

    .logo-2 {
        margin-top: 34px;
    }
    .logo-1,
    .logo-2 {
        margin-left: 100px;
    }

    footer.page-footer .active .popover {
        display: block;
    }

    .divided {
        display: none;
    }

    footer.page-footer .active {
        color: #F51A0E!important;
        display: flex;
        text-transform: uppercase;
        font-weight: 900;
        cursor: pointer;
    }
    footer.page-footer .active hr {
        display: block;
        border-top: 5px solid #F51A0E;
        width: 100%;
        margin: 0;
    }
    footer.page-footer .active + i {
        color: #F51A0E!important;
        font-size: 15px;
    }

    .popovers a {
        font-family: "Roboto";
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }

    .popover-content {
        background: #fff;
        padding: 39px 33px 21px;
        border-radius: 0;
        position: absolute;
        top: -23.5em;
        left: -250px;
        z-index: 4;
        max-width: 352px;
        color: #333;
        box-shadow: 0 0 10px 4px rgba(190,190,190,0.5);
    }

    .popover-content.-arrow {
        margin-top: 10px;
    }

    .popover-content.-arrow::before,
    .popover-content::after {
        content: "";
        position: absolute;
        left: 270px;
        top: 330px;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: 20px solid #fff;
    }

    .context-menu {
        width: 200px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .context-menu-item {
        -webkit-transition: background-color 0.1s;
        transition: background-color 0.1s;
    }

    .context-menu-link {
        display: block;
        padding: 3px 7px;
        text-decoration: none;
        color: #333;
    }
    .context-menu-link:visited {
        color: #333;
    }
    .context-menu-link:hover {
        color: #fff;
        background-color: #3367d6;
    }
    footer.page-footer .contact p
    {
       flex-direction: row
   }

}
@media only screen and (min-width: 1200px) {

}
@media only screen and (min-width: 1500px) {

}

.padding-tab {
    padding-left: 178px;
    padding-right: 185px;
}


@media only screen and (min-width: 320px) {
    .card-header{
        padding: 15px 16px;
    }
    .Acordion .card .card-body,
    .Acordion .card-body{
        padding-left: 55px;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 11px;  
        background-color: #f5f5f5;
    }
    .Acordion .card-body{
        padding-left: 55px;
    }
    .Acordion .card .card-body h3,
    .Acordion .card-body h3{
        color: #1C1C1C;	 
        font-size: 12px;	
        font-weight: bold;	
        line-height: 21px;
    }
    .Acordion .card .card-body p,
    .Acordion .card-body p{
        color: #1C1C1C;
        font-size: 12px;	
        line-height: 19px;
    }
    .Acordion .card-body p{
        font-weight: 400;
    }
    .content-enlace{
        display: flex;
    }
    .content-enlace .enlace{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-self: center;
        margin-right: 21px;
    }
    .enlace span{
        color: #1C1C1C;	
        font-size: 9px;	
        font-weight: bold;	
        line-height: 10px;
    }

    .card-header .btn{
        color: #1C1C1C;	
        font-family: "Roboto";	
        font-size: 12px;	
        font-weight: bold;	
        line-height: inherit;
        padding: 0;
        margin: 0;
        text-align: left;
        display: flex
    }
    .border-collapse{
        border-bottom: 1px solid #DFDFDF;
    }
    .card-header .btn i{
        font-size: 20px;
        margin-right: 16px
    }
    .enlaceLimit{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .enlaceLimit img{
        width: 50px;
        margin-bottom: 10px;
    }
    .enlaceLimit span{
        color: #1C1C1C;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        line-height: 12px;
        margin-bottom: 10px;
    }
    
    .Acordion .card{
        background-color: #f5f5f5;
    }
    .test-kit .card-title{
        font-size: 16px;
        font-weight: 500;
    }
    .test-kit .btn span{
        font-weight: 500;
    }
    
    .btn-link:focus{
        text-decoration: none;
    }
    .btn-link:hover  span{
        text-decoration: none;
    }
    
    .Acordion .content-section .form-inline input::-webkit-input-placeholder{
        font-size: 18px;
    }
    
    .Acordion .content-section .form-inline input::-moz-placeholder{
        font-size: 18px;
    }
    
    .Acordion .content-section .form-inline input:-ms-input-placeholder{
        font-size: 18px;
    }
    
    .Acordion .content-section .form-inline input::-ms-input-placeholder{
        font-size: 18px;
    }
    
    .Acordion .content-section .form-inline input::placeholder{
        font-size: 18px;
    }
    .Acordion .content-section .form-inline .form-control{
        padding: 13px 10px;
        font-size: 18px;
        background: url(/static/media/lupa.9a431366.svg) no-repeat right;
        margin: 0;
    }
    .Acordion .content-section .form-inline input[type=text]:focus:not([readonly]){
        border-bottom: 1px solid #1C1C1C
      }
    
      .md-form input[type=text]:focus:not([readonly]){
          box-shadow: none;
      }
      .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link:hover{
          text-decoration: none
      }

}
@media only screen and (min-width: 992px) {
    .Acordion .card .card-body,
    .Acordion .card-body{
        display: flex;
        justify-content: space-between;
        padding-left: 95px;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 11px;  
        background-color: #f5f5f5;
    }
    .Acordion .card-body{
        padding-left: 55px;
    }
    .Acordion .card .card-body h3,
    .Acordion .card-body h3{
        color: #1C1C1C;	 
        font-size: 18px;	
        font-weight: bold;	
        line-height: 21px;
    }
    .Acordion .card .card-body p,
    .Acordion .card-body p{
        color: #1C1C1C;
        font-size: 16px;	
        line-height: 19px;
    }
    .Acordion .card-body p{
        font-weight: 400;
    }
    .content-enlace{
        display: flex;
    }
    .content-enlace .enlace{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-self: center;
        margin-right: 21px;
    }
    .enlace span{
        color: #1C1C1C;	
        font-size: 9px;	
        font-weight: bold;	
        line-height: 10px;
    }

    .card-header{
        padding: 16px 25px;
    }
    .card-header .btn{
        color: #1C1C1C;	
        font-family: "Roboto";	
        font-size: 18px;	
        font-weight: bold;	
        line-height: inherit;
        padding: 0;
        margin: 0;
    }
    .border-collapse{
        border-bottom: 1px solid #DFDFDF;
    }
    .card-header .btn i{
        font-size: 25px;
        margin-right: 45px
    }
    .enlaceLimit{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .enlaceLimit img{
        width: 65px;
        height: 73px;
        margin-bottom: 10px;
    }
    .enlaceLimit span{
        color: #1C1C1C;
        font-size: 14px;
        /* width: 200px; */
        text-align: center;
        font-weight: 500;
        line-height: 14px;
        margin-bottom: 10px;
    }

    .Acordion .card{
        background-color: #f5f5f5;
    }
    .test-kit .card-title{
        font-size: 16px;
        font-weight: 500;
    }
    .test-kit .btn span{
        font-weight: 500;
    }

    .btn-link:focus{
        text-decoration: none;
    }


    .Acordion .content-section .form-inline input::-webkit-input-placeholder{
        font-size: 18px;
    }


    .Acordion .content-section .form-inline input::-moz-placeholder{
        font-size: 18px;
    }


    .Acordion .content-section .form-inline input:-ms-input-placeholder{
        font-size: 18px;
    }


    .Acordion .content-section .form-inline input::-ms-input-placeholder{
        font-size: 18px;
    }


    .Acordion .content-section .form-inline input::placeholder{
        font-size: 18px;
    }
    .Acordion .content-section .form-inline .form-control{
        padding: 13px 10px;
        font-size: 18px;
        background: url(/static/media/lupa.9a431366.svg) no-repeat right;
        margin: 0;
    }
    .Acordion .content-section .form-inline input[type=text]:focus:not([readonly]){
        border-bottom: 1px solid #1C1C1C
    }

  .md-form input[type=text]:focus:not([readonly]){
      box-shadow: none;
  }
}

@media only screen and (min-width: 320px) {

    /* -------------------------- Accordion -------------------- */
    .title-section{
      position: relative;
    }
    .title-section img{
      width: 100%;
      height: 90px;;
    }
    .titleContent{
      position: absolute;
      display: flex;
      height: 100px;
      width: 100%;
      align-items: center;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
    }
    .titleContent h3{
      color: #FFFFFF;	
      font-family: "Roboto";	
      font-size: 25px;	
      font-weight: bold;	
      line-height: 44px;
      margin-left: 20px
    }
    .acordionContent{
      background-color: #F5F5F5;     
    }
    .accordion {
       margin: 50px auto;
    }
    
    .title {
      color: #1C1C1C;	
      font-family: "Roboto";	
      background-color: #F5F5F5;
      font-size: 18px;	
      font-weight: bold;	
      text-transform: uppercase;
      text-align: left;
      position: relative;
      padding: 16px 10px;
      border-radius: 4px;
      margin-top: 2px;
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    }
    
    .title-text {
      margin-left: 85px;
    }
    .title:hover {
      cursor: pointer;
      background-color: #F5F5F5;     
    }
    .content {
      max-height: 0px;
      border-radius: 4px;
      color: #1C1C1C;
      font-size: 14px;
      text-align: center;
      position: relative;
      text-align: left;
      -webkit-transition: max-height 1s;
      transition: max-height 1s;
      overflow: hidden;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

    }
    
    .content-open {
      background-color: #F5F5F5;
      margin-top: 0px;
      max-height: 300px;
      -webkit-transition: max-height 1s;
      transition: max-height 1s;
    }
    
    .content-text {
      padding: 26px 0 17px 95px;
    } 
    .content-text h3{
      font-size: 18px;	
      font-weight: bold;	
      line-height: 21px;
      font-family: "Roboto";
    }
    .content-text p{
      color: #1C1C1C;	
      font-family: "Roboto";	
      font-size: 16px;	
      font-weight: 400;	
      line-height: 19px;
      margin-bottom: 13px;
    }

    .arrow-wrapper {
      position: absolute;
      left: 25px;
      top: 10px;
      font-size: 25px;
    }
}
@media only screen and (min-width: 576px) {
  .titleContent h3{
    margin-left: 75px
  }
}
@media only screen and (min-width: 992px) {
  .title-section img{
    height: 159px;;
  }
    .titleContent h3{
      font-size: 40px;	
      margin-left: 100px;
    }
    .accordion{
      background-color: #F5F5F5;
    }

}
@media only screen and (min-width: 1024px) {
  
}
@media only screen and (min-width: 1200px) {
  
}
@media only screen and (min-width: 1400px) {
  .title-section img{
    height: 199px;;
  }
}

.nav-tabs {
  border-bottom: 1px solid #DFDFDF;
}
.backgroung-btnRed{
  background-color: #F51A0E;
}
.backgroung-btnBlack{
  background-color: #000;
}
.nav-table .nav-tabs .nav-link.active,
.modal-title,
.modal-body label,
.info-product .namesFichas,
table.table td, table.table th,
.modal-dialog .modal-content .modal-body .nav-link.active,
.titleLimitProdut,
.titleMain
{
  color: #1C1C1C;	

}
.modal-content .modal-body .form-control{
  background-color: #F5F5F5;
  padding: .6rem 0rem .4rem 0.8rem;
  width: calc(100% - 0.8rem)
}
@media only screen and (min-width: 320px) {
  /* --------------------------------- NAV - TABLE ------------------------ */
    .nav-table .nav-tabs .nav-link.active,
    .modal-dialog .modal-content .modal-body .nav-link.active {
      border-bottom:5px solid #F51A0E;
    }
    .nav-table .nav-tabs .nav-link{
      color: #AAAAAA;	
      font-family: "Roboto";	
      font-size: 18px;	
      font-weight: bold;	
      line-height: 21px;
      background-color: #fff;
    }
    .modal-dialog .modal-content .modal-body .nav-link{
      color: #AAAAAA;	
      font-family: "Roboto";	
      font-size: 18px;	
      font-weight: 600;	
      line-height: 21px;
      padding: 15px;
  
    }
    .nav-table .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-radius: 0;
      border-bottom: 0;
    }
    .nav-table .nav-link {
      padding: 24px 2rem;
    }
  
    /* ----------------------------- TAB - CONTENT -------------------------- */
    .content-table .tab-pane{
      padding-top: 23px;
    }
    /* --- filter - BUTTON NEW ---- */
    .addbtn{
      display: flex;
      padding: 15px 0
    }
    .addbtn .form-inline{
      padding: 0 10px;
      width: 350px;
      border-bottom: 1px solid #ced4da;
    }
    .addbtn .md-form.product input[type=text] {
      border-bottom: none;
    }
    .custom-select.tableSelect {
      width: 350px;
      text-transform: uppercase;
      box-shadow: none;
    }
    .custom-select.tableSelect:focus {
      border: 1px solid #1C1C1C
    }
    .form-control:focus {
      border-color: #1C1C1C;
      outline: 0;
      box-shadow:none;
    }
    .md-form input[type=text]:focus:not([readonly]){
      border-bottom: 1px solid #1C1C1C
    }
  
    .md-form.md-outline textarea.md-textarea:focus:not([readonly]) {
      border-color: #1C1C1C;
      box-shadow: none; 
    }
    .custom-select.noticia{
      width: 400px;
    }
    .modalBtn{
      padding: .5rem 1rem;
      border: none;
      color: #FFF;
      border-radius: 2px;
      font-size: 16px;
   }
   /* ------------ Modal ------------------ */
   .modal-title{
    font-size: 18px;
    font-weight: bold;
   }
   .modal-body .label,
   .info-product .namesFichas,
   .titleLimitProdut{
     font-size: 16px;
     font-weight: 400
   }
   .modal-dialog{
    max-width: 750px;
    margin: 200px auto;
    text-align: left;
    }
    .addbtn .modal-content {
      padding: 0;
      text-align: left;
    }
    .modal-header,
    .modal-body,
    .modal-footer{
      padding: 25px;
    }
    .info-product .namesFichas{
      width: 150px;
    }
    .icondelete{
      position: absolute;
      left: 0;
      z-index: 15;
      line-height: 0
    }
    .icondelete img{
      width: 20px;
    }
    .btnIcon{
      background-color: transparent;
      border: none;
      border-bottom: 3px solid #F51A0E;
      padding: 0;
      line-height: 0
    }
    .iconadd{
      margin: 15px;
      position: relative;
    }
    .iconadd .btnIcon img{
      width: calc(1.5em + .75rem + 2px);
    }
    .iconadd .btnIcon{
      border: none;
      position: absolute;
      z-index: 20;
    }
    .borderImage{
      border: 1px solid #ededed
    }
    .show{
      visibility: visible;
    }
    .hide{
      visibility: hidden;
    }
    .content-Img {
      width: 100%;
      height: 200px;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      z-index: 1;
    }
    .content-Img .selectStatus{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .btnAdd{
      background-color: transparent;
      border: 0;
    }
    .btnAdd img{
      width: calc(1.5em + .75rem + 2px);
      margin-top: 0;
    }
    .Limit{
      align-items: center;
    }
    .descrip{
      width: 500px;;
    }
    .addbtn .form-control::-webkit-input-placeholder{
      font-size: 16px;
    }
    .addbtn .form-control::-moz-placeholder{
      font-size: 16px;
    }
    .addbtn .form-control:-ms-input-placeholder{
      font-size: 16px;
    }
    .addbtn .form-control::-ms-input-placeholder{
      font-size: 16px;
    }
    .addbtn .form-control::placeholder{
      font-size: 16px;
    }
    .form-inline .form-control{
      font-size: 16px;
    }
    .Limit .md-form .form-control{
      width: 370px;
    }
    /* ---------------------------- TABLE --------------------- */
    table.table th{
      background-color: #F5F5F5;  	
      font-size: 16px;	
      font-weight: bold;	
      vertical-align: middle
    }
    table.table td{
      font-size: 14px;	
      font-weight: 400
    }
    table.table td, table.table th{
      border: 1px solid #ddd;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-left: 32px;
      padding-right: 32px;
      text-align: center;
      vertical-align: middle;
    }

  
  }
  

@media only screen and (min-width: 992px) {
/* --------------------------------- NAV - TABLE ------------------------ */
  .nav-table .nav-tabs .nav-link.active,
  .modal-dialog .modal-content .modal-body .nav-link.active {
    border-bottom:5px solid #F51A0E;
  }
  .nav-table .nav-tabs .nav-link{
    color: #AAAAAA;	
    font-family: "Roboto";	
    font-size: 18px;	
    font-weight: bold;	
    line-height: 21px;
    background-color: #fff;
  }
  .modal-dialog .modal-content .modal-body .nav-link{
    color: #AAAAAA;	
    font-family: "Roboto";	
    font-size: 18px;	
    font-weight: 600;	
    line-height: 21px;
    padding: 15px;

  }
  .nav-table .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-radius: 0;
    border-bottom: 0;
  }
  .nav-table .nav-link {
    padding: 24px 2rem;
  }

  /* ----------------------------- TAB - CONTENT -------------------------- */
  .content-table .tab-pane{
    padding-top: 23px;
  }
  /* --- filter - BUTTON NEW ---- */
  .addbtn{
    display: flex;
    padding: 15px 0
  }
  .addbtn .form-inline{
    padding: 0 10px;
    width: 350px;
    border-bottom: 1px solid #ced4da;
  }
  .addbtn .md-form.product input[type=text] {
    border-bottom: none;
  }
  .custom-select.tableSelect {
    width: 350px;
    text-transform: uppercase;
    box-shadow: none;
  }
  .custom-select.tableSelect:focus {
    border: 1px solid #1C1C1C
  }
  .form-control:focus {
    border-color: #1C1C1C;
    outline: 0;
    box-shadow:none;
  }
  .md-form input[type=text]:focus:not([readonly]){
    border-bottom: 1px solid #1C1C1C
  }

  .md-form.md-outline textarea.md-textarea:focus:not([readonly]) {
    border-color: #1C1C1C;
    box-shadow: none; 
  }
  .custom-select.noticia{
    width: 400px;
  }
  .modalBtn{
    padding: .5rem 1rem;
    border: none;
    color: #FFF;
    border-radius: 2px;
    font-size: 16px;
 }
 /* ------------ Modal ------------------ */
 .modal-title{
  font-size: 18px;
  font-weight: bold;
 }
 .modal-body .label,
 .info-product .namesFichas,
 .titleLimitProdut{
   font-size: 16px;
   font-weight: 400
 }
 .modal-dialog{
  max-width: 750px;
  margin: 200px auto;
  text-align: left;
  }
  .addbtn .modal-content {
    padding: 0;
    text-align: left;
  }
  .modal-header,
  .modal-body,
  .modal-footer{
    padding: 25px;
  }
  .info-product .namesFichas{
    width: 150px;
  }
  .icondelete{
    position: absolute;
    left: 0;
    z-index: 5;
    line-height: 0
  }
  .icondelete img{
    width: 20px;
  }
  .btnIcon{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #F51A0E;
    padding: 0;
    line-height: 0
  }
  .iconadd{
    margin: 15px
  }
  .iconadd .btnIcon img{
    width: calc(1.5em + .75rem + 2px);
  }
  .iconadd .btnIcon{
    border: none;
  }
  .borderImage{
    border: 1px solid #ededed
  }
  .show{
    visibility: visible;
  }
  .hide{
    visibility: hidden;
  }
  .content-Img {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1;
  }
  .content-Img .selectStatus{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 5px 0;
    background: white;
    align-items: center;
  }
  .btnAdd{
    background-color: transparent;
    border: 0;
  }
  .btnAdd img{
    width: calc(1.5em + .75rem + 2px);
    margin-top: 0;
  }
  .Limit{
    align-items: center;
  }
  .descrip{
    width: 500px;;
  }
  .addbtn .form-control::-webkit-input-placeholder{
    font-size: 16px;
  }
  .addbtn .form-control::-moz-placeholder{
    font-size: 16px;
  }
  .addbtn .form-control:-ms-input-placeholder{
    font-size: 16px;
  }
  .addbtn .form-control::-ms-input-placeholder{
    font-size: 16px;
  }
  .addbtn .form-control::placeholder{
    font-size: 16px;
  }
  .form-inline .form-control{
    font-size: 16px;
  }
  .Limit .md-form .form-control{
    width: 370px;
  }
  /* ---------------------------- TABLE --------------------- */
  table.table th{
    background-color: #F5F5F5;  	
    font-size: 16px;	
    font-weight: bold;	
    vertical-align: middle
  }
  table.table td{
    font-size: 14px;	
    font-weight: 400
  }
  table.table td, table.table th{
    border: 1px solid #ddd;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    vertical-align: middle;
  }

  .custom-control-input:checked~.custom-control-label::before{
    border-color: #F51A0E;
    background-color: #F51A0E;
  } 


}

