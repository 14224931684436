
@media only screen and (min-width: 320px) {

  /* ------------ NOTICIAS ------------- */
  .background-general{
    background-color: #F5F5F5;
  }
  
  .border-contentImage{
    border: unset;
  }
  .border-bottom{
    border-bottom: 1px solid #D4D4D4;
  }
  .section-slide{
    padding-top: 50px;
    padding-bottom: 90px;
  }
  .section-slide h3{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 20px;	
    font-weight: bold;	
    margin-bottom: 0;
    margin-left: 5px;
  }
  .content-img{
    height: 184px;	
    width: 100%;	
    border: 1px solid #DFDFDF;
    align-self: center;
    margin: 0 auto;
  }

  .content-btn{
    display: none;
  }
  .content-info{
    padding-top: 25px;
    padding-bottom: 11px;
  }
  .content-info p,
  .content-info h4,
  .section-slide h3{
    color: #1C1C1C;	
  }
  .content-info h4{
    font-family: "Roboto";	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 16px;
    margin-bottom: 11px;
  }
  .content-info p{
    font-family: "Roboto";	
    font-size: 14px;	
    line-height: 20px;
    font-weight: 400;
  }
  
  .content-info button{
    border: 0;	
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 0;
    padding: 5px 0 0px;
    border-bottom: 3px solid #F51A0E;
    background-color: transparent;
    font-weight: 800;
    margin-top: 5px
  }
  
  .noticiasMas{
    height: 52px;	
    width: 231.83px;
    font-size: 18px;
    border: 1px solid #AAAAAA;	
    border-radius: 26px;
    margin: 0 30px;
    background: #FFFFFF;
    color: #1C1C1C;
    font-weight: bold;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .carousel{
    padding: 0 5px;
  }
  .carousel__slide{
    padding-bottom: 25px;
    padding-top: 15px;
  }
  .btn-next{
    color: #1C1C1C;
    border-bottom:3px solid #F51A0E;
    line-height: 0;
    font-weight: 500
  }
  .section-certificacion .card{
    box-shadow: none;
    padding:10px;

  }
  .section-certificacion .card[class*=border] {
    border: none;
  }
  .section-certificacion .card .card-img-top{
    width: 350px;
  }
  .section-certificacion .card .card-body{
    padding: 15px 0;
  }
  .section-certificacion .card .card-text{
    color: #1C1C1C
  }

  .content-certificaciones{
    padding: 20px 20px;
    background: #F5F5F5;
    border-radius: 20px;
  }
  .section-noticia .content-certificaciones img{
    width: 100%;
    margin: 15px auto;
  }
  .section-noticia .images{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .section-noticia .body-info{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 16px;	
    line-height: 26px;
    font-weight: 400;
    width: 100%;
  }
  .section-noticia .body-info a{
    color: #1C1C1C;	
    font-family: "Roboto";	
    font-size: 16px;	
    line-height: 26px;
    font-weight: 400;
    width: 100%;
  }
}
  
  @media only screen and (min-width: 576px) {
  
    
  }
  
  @media only screen and (min-width: 768px) {
  
    
  }
  
  @media only screen and (min-width: 992px) {
  
    /* ------------------------ NOTICIAS ----------------- */
    
    .section-slide{
      padding-top: 125px;
      padding-bottom: 190px;
    }
    .section-slide h3{
      font-size: 40px;	
      line-height: 44px;
      margin-bottom: 44px
    }
  
    .item-slide h1 {
      text-transform: uppercase;
    }

    .carousel{
      padding:0
    }
    .prev, .next {
      cursor: pointer;
      width: auto;
      height: 82px;
      width: 82px;;
      padding: 30px 30px;
      font-weight: bold;
      font-size: 18px;
      transition: 1s ease;
      border: 1px solid #DFDFDF;	
      background-color: #FFFFFF;
      color: #F51A0E;
    }
  
    .content-btn{
      position: absolute;
      z-index: 2;
      bottom: 25px;
      left: 301px;
      display: flex
    }
    .content-info{
      padding-left: 95px;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-right: 10px;
      text-align: left;
    }

    .content-info h4{
      font-size: 20px;	
      line-height: 28px;
      margin-bottom: 9px;
    }
    .content-info p{
      font-size: 16px;	
      line-height: 22px;
      margin-bottom: 0;
    }
 
    .content-img{
      height: 350px;
    } 

    .content-info button{
      position: unset;
    }
    .prev:hover, .next:hover {
      background-color: rgba(0,0,0,0.8);
    }
  
    .noticiasMas{
      margin: 20px auto 0;
    }

    .carousel-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 210px;
    }
    
    .carousel {
      position: relative;
    }
    
    .carousel__slide {
      margin-right: auto;
      margin-left: auto;
      display: none;
      list-style-type: none;
      text-align: center;
    }
    @media (max-width: 991px) {
      .carousel__slide {
        padding-right: 60px;
        padding-left: 60px;
      }
    }
    .carousel__slide--active {
      display: block;
    }
    
    .carousel-slide__content {
      margin-bottom: 19px;
      font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
      font-size: 16px;
    }
    @media (max-width: 991px) {
      .carousel-slide__content {
        font-size: 18px;
      }
    }
    
    .carousel-slide__author,
    .carousel-slide__source {
      font-family: 'Roboto', arial, sans-serif;
      font-size: 14px;
    }
    @media (min-width: 992px) {
      .carousel-slide__author,
      .carousel-slide__source {
        font-size: 16px;
      }
    }
    
    .carousel-slide__source {
      font-style: italic;
      color: #888;
    }
    .section-certificacion .card{
      box-shadow: none;
      padding: 40px 100px;

    }
    .section-certificacion .card[class*=border] {
      border: none;
    }
    .section-certificacion .card .card-img-top{
      width: 350px;
    }
    .section-certificacion .card .card-body{
      padding: 15px 0;
    }
    .section-certificacion .card .card-text{
      color: #1C1C1C
    }

    .content-certificaciones{
      padding: 40px 100px;
      background: #F5F5F5;
      border-radius: 20px;
    }
    .section-noticia .content-certificaciones img{
      width: 500px;
      margin: 15px auto;
    }
    .section-noticia .images{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .section-noticia .body-info{
      color: #1C1C1C;	
      font-family: "Roboto";	
      font-size: 16px;	
      line-height: 26px;
      font-weight: 400;
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 1024px) {

    
  }
          
  @media only screen and (min-width: 1200px) {
    .content-btn{
      left: 391px;
   }

  }
            