.nav-tabs {
  border-bottom: 1px solid #DFDFDF;
}
.backgroung-btnRed{
  background-color: #F51A0E;
}
.backgroung-btnBlack{
  background-color: #000;
}
.nav-table .nav-tabs .nav-link.active,
.modal-title,
.modal-body label,
.info-product .namesFichas,
table.table td, table.table th,
.modal-dialog .modal-content .modal-body .nav-link.active,
.titleLimitProdut,
.titleMain
{
  color: #1C1C1C;	

}
.modal-content .modal-body .form-control{
  background-color: #F5F5F5;
  padding: .6rem 0rem .4rem 0.8rem;
  width: calc(100% - 0.8rem)
}
@media only screen and (min-width: 320px) {
  /* --------------------------------- NAV - TABLE ------------------------ */
    .nav-table .nav-tabs .nav-link.active,
    .modal-dialog .modal-content .modal-body .nav-link.active {
      border-bottom:5px solid #F51A0E;
    }
    .nav-table .nav-tabs .nav-link{
      color: #AAAAAA;	
      font-family: "Roboto";	
      font-size: 18px;	
      font-weight: bold;	
      line-height: 21px;
      background-color: #fff;
    }
    .modal-dialog .modal-content .modal-body .nav-link{
      color: #AAAAAA;	
      font-family: "Roboto";	
      font-size: 18px;	
      font-weight: 600;	
      line-height: 21px;
      padding: 15px;
  
    }
    .nav-table .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-radius: 0;
      border-bottom: 0;
    }
    .nav-table .nav-link {
      padding: 24px 2rem;
    }
  
    /* ----------------------------- TAB - CONTENT -------------------------- */
    .content-table .tab-pane{
      padding-top: 23px;
    }
    /* --- filter - BUTTON NEW ---- */
    .addbtn{
      display: flex;
      padding: 15px 0
    }
    .addbtn .form-inline{
      padding: 0 10px;
      width: 350px;
      border-bottom: 1px solid #ced4da;
    }
    .addbtn .md-form.product input[type=text] {
      border-bottom: none;
    }
    .custom-select.tableSelect {
      width: 350px;
      text-transform: uppercase;
      box-shadow: none;
    }
    .custom-select.tableSelect:focus {
      border: 1px solid #1C1C1C
    }
    .form-control:focus {
      border-color: #1C1C1C;
      outline: 0;
      box-shadow:none;
    }
    .md-form input[type=text]:focus:not([readonly]){
      border-bottom: 1px solid #1C1C1C
    }
  
    .md-form.md-outline textarea.md-textarea:focus:not([readonly]) {
      border-color: #1C1C1C;
      box-shadow: none; 
    }
    .custom-select.noticia{
      width: 400px;
    }
    .modalBtn{
      padding: .5rem 1rem;
      border: none;
      color: #FFF;
      border-radius: 2px;
      font-size: 16px;
   }
   /* ------------ Modal ------------------ */
   .modal-title{
    font-size: 18px;
    font-weight: bold;
   }
   .modal-body .label,
   .info-product .namesFichas,
   .titleLimitProdut{
     font-size: 16px;
     font-weight: 400
   }
   .modal-dialog{
    max-width: 750px;
    margin: 200px auto;
    text-align: left;
    }
    .addbtn .modal-content {
      padding: 0;
      text-align: left;
    }
    .modal-header,
    .modal-body,
    .modal-footer{
      padding: 25px;
    }
    .info-product .namesFichas{
      width: 150px;
    }
    .icondelete{
      position: absolute;
      left: 0;
      z-index: 15;
      line-height: 0
    }
    .icondelete img{
      width: 20px;
    }
    .btnIcon{
      background-color: transparent;
      border: none;
      border-bottom: 3px solid #F51A0E;
      padding: 0;
      line-height: 0
    }
    .iconadd{
      margin: 15px;
      position: relative;
    }
    .iconadd .btnIcon img{
      width: calc(1.5em + .75rem + 2px);
    }
    .iconadd .btnIcon{
      border: none;
      position: absolute;
      z-index: 20;
    }
    .borderImage{
      border: 1px solid #ededed
    }
    .show{
      visibility: visible;
    }
    .hide{
      visibility: hidden;
    }
    .content-Img {
      width: 100%;
      height: 200px;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      z-index: 1;
    }
    .content-Img .selectStatus{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .btnAdd{
      background-color: transparent;
      border: 0;
    }
    .btnAdd img{
      width: calc(1.5em + .75rem + 2px);
      margin-top: 0;
    }
    .Limit{
      align-items: center;
    }
    .descrip{
      width: 500px;;
    }
    .addbtn .form-control::placeholder{
      font-size: 16px;
    }
    .form-inline .form-control{
      font-size: 16px;
    }
    .Limit .md-form .form-control{
      width: 370px;
    }
    /* ---------------------------- TABLE --------------------- */
    table.table th{
      background-color: #F5F5F5;  	
      font-size: 16px;	
      font-weight: bold;	
      vertical-align: middle
    }
    table.table td{
      font-size: 14px;	
      font-weight: 400
    }
    table.table td, table.table th{
      border: 1px solid #ddd;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-left: 32px;
      padding-right: 32px;
      text-align: center;
      vertical-align: middle;
    }

  
  }
  

@media only screen and (min-width: 992px) {
/* --------------------------------- NAV - TABLE ------------------------ */
  .nav-table .nav-tabs .nav-link.active,
  .modal-dialog .modal-content .modal-body .nav-link.active {
    border-bottom:5px solid #F51A0E;
  }
  .nav-table .nav-tabs .nav-link{
    color: #AAAAAA;	
    font-family: "Roboto";	
    font-size: 18px;	
    font-weight: bold;	
    line-height: 21px;
    background-color: #fff;
  }
  .modal-dialog .modal-content .modal-body .nav-link{
    color: #AAAAAA;	
    font-family: "Roboto";	
    font-size: 18px;	
    font-weight: 600;	
    line-height: 21px;
    padding: 15px;

  }
  .nav-table .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-radius: 0;
    border-bottom: 0;
  }
  .nav-table .nav-link {
    padding: 24px 2rem;
  }

  /* ----------------------------- TAB - CONTENT -------------------------- */
  .content-table .tab-pane{
    padding-top: 23px;
  }
  /* --- filter - BUTTON NEW ---- */
  .addbtn{
    display: flex;
    padding: 15px 0
  }
  .addbtn .form-inline{
    padding: 0 10px;
    width: 350px;
    border-bottom: 1px solid #ced4da;
  }
  .addbtn .md-form.product input[type=text] {
    border-bottom: none;
  }
  .custom-select.tableSelect {
    width: 350px;
    text-transform: uppercase;
    box-shadow: none;
  }
  .custom-select.tableSelect:focus {
    border: 1px solid #1C1C1C
  }
  .form-control:focus {
    border-color: #1C1C1C;
    outline: 0;
    box-shadow:none;
  }
  .md-form input[type=text]:focus:not([readonly]){
    border-bottom: 1px solid #1C1C1C
  }

  .md-form.md-outline textarea.md-textarea:focus:not([readonly]) {
    border-color: #1C1C1C;
    box-shadow: none; 
  }
  .custom-select.noticia{
    width: 400px;
  }
  .modalBtn{
    padding: .5rem 1rem;
    border: none;
    color: #FFF;
    border-radius: 2px;
    font-size: 16px;
 }
 /* ------------ Modal ------------------ */
 .modal-title{
  font-size: 18px;
  font-weight: bold;
 }
 .modal-body .label,
 .info-product .namesFichas,
 .titleLimitProdut{
   font-size: 16px;
   font-weight: 400
 }
 .modal-dialog{
  max-width: 750px;
  margin: 200px auto;
  text-align: left;
  }
  .addbtn .modal-content {
    padding: 0;
    text-align: left;
  }
  .modal-header,
  .modal-body,
  .modal-footer{
    padding: 25px;
  }
  .info-product .namesFichas{
    width: 150px;
  }
  .icondelete{
    position: absolute;
    left: 0;
    z-index: 5;
    line-height: 0
  }
  .icondelete img{
    width: 20px;
  }
  .btnIcon{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #F51A0E;
    padding: 0;
    line-height: 0
  }
  .iconadd{
    margin: 15px
  }
  .iconadd .btnIcon img{
    width: calc(1.5em + .75rem + 2px);
  }
  .iconadd .btnIcon{
    border: none;
  }
  .borderImage{
    border: 1px solid #ededed
  }
  .show{
    visibility: visible;
  }
  .hide{
    visibility: hidden;
  }
  .content-Img {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1;
  }
  .content-Img .selectStatus{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 5px 0;
    background: white;
    align-items: center;
  }
  .btnAdd{
    background-color: transparent;
    border: 0;
  }
  .btnAdd img{
    width: calc(1.5em + .75rem + 2px);
    margin-top: 0;
  }
  .Limit{
    align-items: center;
  }
  .descrip{
    width: 500px;;
  }
  .addbtn .form-control::placeholder{
    font-size: 16px;
  }
  .form-inline .form-control{
    font-size: 16px;
  }
  .Limit .md-form .form-control{
    width: 370px;
  }
  /* ---------------------------- TABLE --------------------- */
  table.table th{
    background-color: #F5F5F5;  	
    font-size: 16px;	
    font-weight: bold;	
    vertical-align: middle
  }
  table.table td{
    font-size: 14px;	
    font-weight: 400
  }
  table.table td, table.table th{
    border: 1px solid #ddd;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    vertical-align: middle;
  }

  .custom-control-input:checked~.custom-control-label::before{
    border-color: #F51A0E;
    background-color: #F51A0E;
  } 


}
