@media only screen and (min-width: 320px) {
.first-section{
  position: relative
}

.content-welcome{
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 73px;
  padding: 0 20px;
  left: 0;
  right: 0;
}
.content-welcome .lines .nav-link{
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.lines-specific{
  width: 100%;
  height: 100%;
}

.lines-specific p{
 opacity: 1;
}
.lines{
  position: relative;
  height: 110px;
  z-index: 0;
}
.fileDownload{
  position: absolute;
  z-index: 50;
  opacity: 0;
  bottom: 20px;
  align-items: center;
  transition: all 1s;
  justify-content: center;
  display: flex;
}
.fileDownload img{
  width: 50px;
}

.content-welcome .columns .lines:hover .img-line{
  z-index: -5;
  max-height: 300px;
}
.content-welcome .columns .lines:hover .nav-link{
  z-index: 10000;
}
.content-welcome .columns .lines .lines-specific:hover p{
  opacity: 0;
}
.content-welcome .columns .lines .lines-specific:hover .fileDownload{
  opacity: 1;
  transition: all 1s;
  color: #fff
}

.imgInfo-content{
  width: 100%;
  height: calc(100vh - 74px);
  background: url("../assets/ship.jpg") no-repeat 0;
  background-size: cover;
}
.info-uniserve{
  width: 280px;
  height: calc(100% - 183px);
  display: flex;
  align-items: center;
  padding:0 20px 0;
  color: #fff;
}

.info-uniserve .text1{
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 13px
}
.info-uniserve .text2{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.content-welcome .columns .lines .img-line {
  display: none;
}
}
@media only screen and (min-width: 576px) {
  .info-uniserve{
    margin-left: 55px;
  }
  .content-welcome{
    width: 100%;
    z-index: 1;
    bottom: 0;
    padding: 0
  }
  /* .imgInfo-content{
    background-position-x: 0px;
    background-position-y: 0px
  } */
}
@media only screen and (min-width: 992px) {

  .imgInfo-content {
    transition: all 1s 
  }
  .fileDownload{
    width: 100%;
    height: 40px;
    bottom: 20px;
    color:#fff;
  }
  .fileDownload img{
    width: 50px;
    margin-right: 10px;
    margin-left: 15px;
  }
  .oscurecer{
    background-color: rgba(0,0,0,0.8);
    filter:brightness(0.7);
  }
  .content-welcome .lines .nav-link{
    text-align: left;
    justify-content: end;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 27px;
  }
  .content-welcome .lines .lines-specific p{
    text-align: left;
    justify-content: end;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 27px;
  }
  .content-welcome .lines p.nav-link{
    font-size: 18px;
    margin-bottom: 0;
    opacity: 1;
    transition: all 1s;
  }

  .content-welcome:hover + .imgInfo-content{
    filter:brightness(0.6);
    z-index: 1000;
  }

  .info-uniserve{
    width: 450px;
    height: calc(100% - 181px);
    padding: 0 0 0 100px;
    margin: 0;
  }
  .info-uniserve .text1{
    font-size: 18px;
    margin-bottom: 18px
  }
  .info-uniserve .text2{
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
  }

  .content-welcome .columns .lines .img-line {
    display: block;
    position: absolute;
    z-index: -10;
    filter: alpha(opacity=30);
    bottom: 0;
    transition: max-height 1s;  
    max-height: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .lines{
    height: 151px;
  }
  .columns .lines .img-line {
    height: 250px;
    margin-top: -101px;
  }
  .content-welcome .columns .lines a {
    font-size: 18px;
  }
  .info-uniserve{
    width: 590px;
    padding: 0 0 0 100px;
  }
  .info-uniserve .text2{
    font-size: 40px;
    line-height: 44px;
  }

}

@media only screen and (min-width: 1500px) {
  .lines{
    height: 181px;
  }

  .columns .lines .img-line {
    height: 300px;
    margin-top: -120px;
  }

  .columns .lines a {
    font-size: 1.2em;
  }
}