@media only screen and (min-width: 320px) {

    /* -------------------------- Accordion -------------------- */
    .title-section{
      position: relative;
    }
    .title-section img{
      width: 100%;
      height: 90px;;
    }
    .titleContent{
      position: absolute;
      display: flex;
      height: 100px;
      width: 100%;
      align-items: center;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
    }
    .titleContent h3{
      color: #FFFFFF;	
      font-family: "Roboto";	
      font-size: 25px;	
      font-weight: bold;	
      line-height: 44px;
      margin-left: 20px
    }
    .acordionContent{
      background-color: #F5F5F5;     
    }
    .accordion {
       margin: 50px auto;
    }
    
    .title {
      color: #1C1C1C;	
      font-family: "Roboto";	
      background-color: #F5F5F5;
      font-size: 18px;	
      font-weight: bold;	
      text-transform: uppercase;
      text-align: left;
      position: relative;
      padding: 16px 10px;
      border-radius: 4px;
      margin-top: 2px;
      transition: all .2s ease-in;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    }
    
    .title-text {
      margin-left: 85px;
    }
    .title:hover {
      cursor: pointer;
      background-color: #F5F5F5;     
    }
    .content {
      max-height: 0px;
      border-radius: 4px;
      color: #1C1C1C;
      font-size: 14px;
      text-align: center;
      position: relative;
      text-align: left;
      transition: max-height 1s;
      overflow: hidden;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

    }
    
    .content-open {
      background-color: #F5F5F5;
      margin-top: 0px;
      max-height: 300px;
      transition: max-height 1s;
    }
    
    .content-text {
      padding: 26px 0 17px 95px;
    } 
    .content-text h3{
      font-size: 18px;	
      font-weight: bold;	
      line-height: 21px;
      font-family: "Roboto";
    }
    .content-text p{
      color: #1C1C1C;	
      font-family: "Roboto";	
      font-size: 16px;	
      font-weight: 400;	
      line-height: 19px;
      margin-bottom: 13px;
    }

    .arrow-wrapper {
      position: absolute;
      left: 25px;
      top: 10px;
      font-size: 25px;
    }
}
@media only screen and (min-width: 576px) {
  .titleContent h3{
    margin-left: 75px
  }
}
@media only screen and (min-width: 992px) {
  .title-section img{
    height: 159px;;
  }
    .titleContent h3{
      font-size: 40px;	
      margin-left: 100px;
    }
    .accordion{
      background-color: #F5F5F5;
    }

}
@media only screen and (min-width: 1024px) {
  
}
@media only screen and (min-width: 1200px) {
  
}
@media only screen and (min-width: 1400px) {
  .title-section img{
    height: 199px;;
  }
}
